/*
File generated by js-routes 1.4.3
Based on Rails routes of Shasta::Application
 */

(function() {
  var DeprecatedBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, Utils, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  ParameterMissing = function(message) {
    this.message = message;
  };

  ParameterMissing.prototype = new Error();

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  SpecialOptionsKey = "_options";

  DeprecatedBehavior = false;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = DeprecatedBehavior || actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          if (value != null) {
            delete parameters[left];
            return this.path_identifier(value);
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      if (left.replace(/^\*/i, "") !== left) {
        route[1] = left = left.replace(/^\*/i, "");
      }
      value = parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      parameters[left] = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      return this.visit(route, parameters, optional);
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace.split(".");
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// account_availability => /app/accounts/availability(.:format)
  // function(options)
  account_availability_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"availability",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// account_payment => /:account_slug/payments/:id(.:format)
  // function(account_slug, id, options)
  account_payment_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// account_payments => /:account_slug/payments(.:format)
  // function(account_slug, options)
  account_payments_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// account_validation => /app/accounts/validate(.:format)
  // function(options)
  account_validation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// activate_self_service_customer => /:account_slug/customers/:id/activate_self_service(.:format)
  // function(account_slug, id, options)
  activate_self_service_customer_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"activate_self_service",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// ajax_update_job_type => /:account_slug/job_types/:id/ajax_update(.:format)
  // function(account_slug, id, options)
  ajax_update_job_type_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"job_types",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"ajax_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_def => /api-def(.:format)
  // function(options)
  api_def_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api-def",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// api_docs => /api-docs(.:format)
  // function(options)
  api_docs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api-docs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// api_sign_up => /app/free_trial(.:format)
  // function(options)
  api_sign_up_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"free_trial",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_v2_accounts_info => /:account_slug/api/v2/accounts/info.:format
  // function(account_slug, format, options)
  api_v2_accounts_info_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"info",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// api_v2_accounts_stripe => /:account_slug/api/v2/accounts/stripe.:format
  // function(account_slug, format, options)
  api_v2_accounts_stripe_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"stripe",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// api_v2_api_account_payment => /:account_slug/api/v2/payments/:id.:format
  // function(account_slug, id, format, options)
  api_v2_api_account_payment_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// api_v2_api_account_payments => /:account_slug/api/v2/payments.:format
  // function(account_slug, format, options)
  api_v2_api_account_payments_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v2_api_edit_account_payment => /:account_slug/api/v2/payments/:id/edit.:format
  // function(account_slug, id, format, options)
  api_v2_api_edit_account_payment_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// api_v2_conversation => /:account_slug/api/v2/conversations/:id.:format
  // function(account_slug, id, format, options)
  api_v2_conversation_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"conversations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// api_v2_conversation_messages => /:account_slug/api/v2/conversations/:conversation_id/messages.:format
  // function(account_slug, conversation_id, format, options)
  api_v2_conversation_messages_path: Utils.route([["account_slug",true],["conversation_id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"conversations",false],[2,[7,"/",false],[2,[3,"conversation_id",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// api_v2_conversations => /:account_slug/api/v2/conversations.:format
  // function(account_slug, format, options)
  api_v2_conversations_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"conversations",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v2_customer => /:account_slug/api/v2/customers/:id.:format
  // function(account_slug, id, format, options)
  api_v2_customer_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// api_v2_customer_job => /:account_slug/api/v2/customers/:customer_id/jobs/:id.:format
  // function(account_slug, customer_id, id, format, options)
  api_v2_customer_job_path: Utils.route([["account_slug",true],["customer_id",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]),
// api_v2_customer_jobs => /:account_slug/api/v2/customers/:customer_id/jobs.:format
  // function(account_slug, customer_id, format, options)
  api_v2_customer_jobs_path: Utils.route([["account_slug",true],["customer_id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// api_v2_customer_note => /:account_slug/api/v2/customers/:customer_id/notes/:id.:format
  // function(account_slug, customer_id, id, format, options)
  api_v2_customer_note_path: Utils.route([["account_slug",true],["customer_id",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]),
// api_v2_customer_notes => /:account_slug/api/v2/customers/:customer_id/notes.:format
  // function(account_slug, customer_id, format, options)
  api_v2_customer_notes_path: Utils.route([["account_slug",true],["customer_id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// api_v2_customer_payment => /:account_slug/api/v2/customers/:customer_id/payments/:id.:format
  // function(account_slug, customer_id, id, format, options)
  api_v2_customer_payment_path: Utils.route([["account_slug",true],["customer_id",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]),
// api_v2_customer_payments => /:account_slug/api/v2/customers/:customer_id/payments.:format
  // function(account_slug, customer_id, format, options)
  api_v2_customer_payments_path: Utils.route([["account_slug",true],["customer_id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// api_v2_customer_tagging => /:account_slug/api/v2/customers/:customer_id/taggings/:id.:format
  // function(account_slug, customer_id, id, format, options)
  api_v2_customer_tagging_path: Utils.route([["account_slug",true],["customer_id",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"taggings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]),
// api_v2_customer_taggings => /:account_slug/api/v2/customers/:customer_id/taggings.:format
  // function(account_slug, customer_id, format, options)
  api_v2_customer_taggings_path: Utils.route([["account_slug",true],["customer_id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"taggings",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// api_v2_customers => /:account_slug/api/v2/customers.:format
  // function(account_slug, format, options)
  api_v2_customers_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v2_docs => /:account_slug/api/v2/documentation(.:format)
  // function(account_slug, options)
  api_v2_docs_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"documentation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_v2_employee => /:account_slug/api/v2/employees/:id.:format
  // function(account_slug, id, format, options)
  api_v2_employee_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// api_v2_employees => /:account_slug/api/v2/employees.:format
  // function(account_slug, format, options)
  api_v2_employees_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v2_invoices => /:account_slug/api/v2/invoices.:format
  // function(account_slug, format, options)
  api_v2_invoices_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"invoices",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v2_item => /:account_slug/api/v2/items/:id.:format
  // function(account_slug, id, format, options)
  api_v2_item_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// api_v2_items => /:account_slug/api/v2/items.:format
  // function(account_slug, format, options)
  api_v2_items_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"items",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v2_job => /:account_slug/api/v2/jobs/:id.:format
  // function(account_slug, id, format, options)
  api_v2_job_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// api_v2_job_charge_type => /:account_slug/api/v2/job_charge_types/:id.:format
  // function(account_slug, id, format, options)
  api_v2_job_charge_type_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"job_charge_types",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// api_v2_job_charge_types => /:account_slug/api/v2/job_charge_types.:format
  // function(account_slug, format, options)
  api_v2_job_charge_types_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"job_charge_types",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v2_job_expense => /:account_slug/api/v2/jobs/:job_id/expenses/:id.:format
  // function(account_slug, job_id, id, format, options)
  api_v2_job_expense_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"expenses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]),
// api_v2_job_expenses => /:account_slug/api/v2/jobs/:job_id/expenses.:format
  // function(account_slug, job_id, format, options)
  api_v2_job_expenses_path: Utils.route([["account_slug",true],["job_id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"expenses",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// api_v2_job_job_charge => /:account_slug/api/v2/jobs/:job_id/job_charges/:id.:format
  // function(account_slug, job_id, id, format, options)
  api_v2_job_job_charge_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"job_charges",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]),
// api_v2_job_job_charges => /:account_slug/api/v2/jobs/:job_id/job_charges.:format
  // function(account_slug, job_id, format, options)
  api_v2_job_job_charges_path: Utils.route([["account_slug",true],["job_id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"job_charges",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// api_v2_job_note => /:account_slug/api/v2/jobs/:job_id/notes/:id.:format
  // function(account_slug, job_id, id, format, options)
  api_v2_job_note_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]),
// api_v2_job_notes => /:account_slug/api/v2/jobs/:job_id/notes.:format
  // function(account_slug, job_id, format, options)
  api_v2_job_notes_path: Utils.route([["account_slug",true],["job_id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// api_v2_job_payment => /:account_slug/api/v2/jobs/:job_id/payments/:id.:format
  // function(account_slug, job_id, id, format, options)
  api_v2_job_payment_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]),
// api_v2_job_payments => /:account_slug/api/v2/jobs/:job_id/payments.:format
  // function(account_slug, job_id, format, options)
  api_v2_job_payments_path: Utils.route([["account_slug",true],["job_id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// api_v2_job_statuses => /:account_slug/api/v2/job_statuses.:format
  // function(account_slug, format, options)
  api_v2_job_statuses_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"job_statuses",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v2_job_tagging => /:account_slug/api/v2/jobs/:job_id/taggings/:id.:format
  // function(account_slug, job_id, id, format, options)
  api_v2_job_tagging_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"taggings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]),
// api_v2_job_taggings => /:account_slug/api/v2/jobs/:job_id/taggings.:format
  // function(account_slug, job_id, format, options)
  api_v2_job_taggings_path: Utils.route([["account_slug",true],["job_id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"taggings",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// api_v2_job_task => /:account_slug/api/v2/jobs/:job_id/tasks/:id.:format
  // function(account_slug, job_id, id, format, options)
  api_v2_job_task_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]),
// api_v2_job_tasks => /:account_slug/api/v2/jobs/:job_id/tasks.:format
  // function(account_slug, job_id, format, options)
  api_v2_job_tasks_path: Utils.route([["account_slug",true],["job_id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// api_v2_job_time_entries => /:account_slug/api/v2/jobs/:job_id/time_entries.:format
  // function(account_slug, job_id, format, options)
  api_v2_job_time_entries_path: Utils.route([["account_slug",true],["job_id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"time_entries",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// api_v2_job_time_entry => /:account_slug/api/v2/jobs/:job_id/time_entries/:id.:format
  // function(account_slug, job_id, id, format, options)
  api_v2_job_time_entry_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"time_entries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]),
// api_v2_job_types => /:account_slug/api/v2/job_types.:format
  // function(account_slug, format, options)
  api_v2_job_types_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"job_types",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v2_jobs => /:account_slug/api/v2/jobs.:format
  // function(account_slug, format, options)
  api_v2_jobs_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v2_json_schedule => /:account_slug/api/v2/schedule.:format
  // function(account_slug, format, options)
  api_v2_json_schedule_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"schedule",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v2_locations => /:account_slug/api/v2/locations.:format
  // function(account_slug, format, options)
  api_v2_locations_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v2_login => /:account_slug/api/v2/login.:format
  // function(account_slug, format, options)
  api_v2_login_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"login",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v2_messages_unread_count => /:account_slug/api/v2/messages/unread_count.:format
  // function(account_slug, format, options)
  api_v2_messages_unread_count_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[6,"unread_count",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// api_v2_note => /:account_slug/api/v2/notes/:id.:format
  // function(account_slug, id, format, options)
  api_v2_note_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// api_v2_notes => /:account_slug/api/v2/notes.:format
  // function(account_slug, format, options)
  api_v2_notes_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v2_opportunities => /:account_slug/api/v2/opportunities.:format
  // function(account_slug, format, options)
  api_v2_opportunities_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v2_opportunity => /:account_slug/api/v2/opportunities/:id.:format
  // function(account_slug, id, format, options)
  api_v2_opportunity_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// api_v2_payment => /:account_slug/api/v2/payments/:id.:format
  // function(account_slug, id, format, options)
  api_v2_payment_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// api_v2_payments => /:account_slug/api/v2/payments.:format
  // function(account_slug, format, options)
  api_v2_payments_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v2_scaffolding => /:account_slug/api/v2/scaffolding.:format
  // function(account_slug, format, options)
  api_v2_scaffolding_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"scaffolding",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v2_search => /:account_slug/api/v2/search.:format
  // function(account_slug, format, options)
  api_v2_search_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"search",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v2_signature => /:account_slug/api/v2/signatures/:id.:format
  // function(account_slug, id, format, options)
  api_v2_signature_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"signatures",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// api_v2_signatures => /:account_slug/api/v2/signatures.:format
  // function(account_slug, format, options)
  api_v2_signatures_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"signatures",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v2_task => /:account_slug/api/v2/tasks/:id.:format
  // function(account_slug, id, format, options)
  api_v2_task_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// api_v2_task_types => /:account_slug/api/v2/task_types.:format
  // function(account_slug, format, options)
  api_v2_task_types_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"task_types",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v2_tasks => /:account_slug/api/v2/tasks.:format
  // function(account_slug, format, options)
  api_v2_tasks_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v2_vendor => /:account_slug/api/v2/vendors/:id.:format
  // function(account_slug, id, format, options)
  api_v2_vendor_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"vendors",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// api_v2_vendors => /:account_slug/api/v2/vendors.:format
  // function(account_slug, format, options)
  api_v2_vendors_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"vendors",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v3_custom_field_configurations => /:account_slug/api/v3/custom_field_configurations.:format
  // function(account_slug, format, options)
  api_v3_custom_field_configurations_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"custom_field_configurations",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v3_customer => /:account_slug/api/v3/customers/:id.:format
  // function(account_slug, id, format, options)
  api_v3_customer_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// api_v3_customers => /:account_slug/api/v3/customers.:format
  // function(account_slug, format, options)
  api_v3_customers_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v3_employees => /:account_slug/api/v3/employees.:format
  // function(account_slug, format, options)
  api_v3_employees_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v3_estimates => /:account_slug/api/v3/estimates.:format
  // function(account_slug, format, options)
  api_v3_estimates_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"estimates",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v3_invoices => /:account_slug/api/v3/invoices.:format
  // function(account_slug, format, options)
  api_v3_invoices_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"invoices",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v3_job => /:account_slug/api/v3/jobs/:id.:format
  // function(account_slug, id, format, options)
  api_v3_job_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// api_v3_jobs => /:account_slug/api/v3/jobs.:format
  // function(account_slug, format, options)
  api_v3_jobs_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v3_payment => /:account_slug/api/v3/payments/:id.:format
  // function(account_slug, id, format, options)
  api_v3_payment_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// api_v3_payments => /:account_slug/api/v3/payments.:format
  // function(account_slug, format, options)
  api_v3_payments_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v3_tasks => /:account_slug/api/v3/tasks.:format
  // function(account_slug, format, options)
  api_v3_tasks_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// api_v3_twilio_lookup => /:account_slug/api/v3/twilio/lookup.:format
  // function(account_slug, format, options)
  api_v3_twilio_lookup_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"twilio",false],[2,[7,"/",false],[2,[6,"lookup",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// approve_job => /:account_slug/jobs/:id/approve(.:format)
  // function(account_slug, id, options)
  approve_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"approve",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// approve_request => /:account_slug/requests/:id/approve(.:format)
  // function(account_slug, id, options)
  approve_request_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"requests",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"approve",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// archive_inbox_conversations => /:account_slug/inbox/conversations/archive(.:format)
  // function(account_slug, options)
  archive_inbox_conversations_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[6,"conversations",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// archive_self_service_inbox_conversations => /:account_slug/self_service/inbox/conversations/archive(.:format)
  // function(account_slug, options)
  archive_self_service_inbox_conversations_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[6,"conversations",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// attachment => /:account_slug/attachments/:id(.:format)
  // function(account_slug, id, options)
  attachment_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// auth_failure => /app/auth/failure(.:format)
  // function(options)
  auth_failure_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"failure",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// auth_quickbooks_callback => /:account_slug/auth/quickbooks/callback(.:format)
  // function(account_slug, options)
  auth_quickbooks_callback_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"quickbooks",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// auth_quickbooks_oauth2_callback => /:account_slug/auth/quickbooks_oauth2/callback(.:format)
  // function(account_slug, options)
  auth_quickbooks_oauth2_callback_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"quickbooks_oauth2",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// auth_stripe_connect_callback => /app/auth/stripe_connect/callback(.:format)
  // function(options)
  auth_stripe_connect_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"stripe_connect",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// autocomplete_api_v2_jobs => /:account_slug/api/v2/jobs/autocomplete.:format
  // function(account_slug, format, options)
  autocomplete_api_v2_jobs_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"autocomplete",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// autosuggest_api_v2_tags => /:account_slug/api/v2/tags/autosuggest.:format
  // function(account_slug, format, options)
  autosuggest_api_v2_tags_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[6,"autosuggest",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// autosuggest_tags => /:account_slug/tags/autosuggest(.:format)
  // function(account_slug, options)
  autosuggest_tags_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[6,"autosuggest",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// availability => /:account_slug/connect/availability(.:format)
  // function(account_slug, options)
  availability_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"connect",false],[2,[7,"/",false],[2,[6,"availability",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// availability_employees => /:account_slug/employees/availability(.:format)
  // function(account_slug, options)
  availability_employees_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"availability",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// back_employee_masquerade_index => /:account_slug/employees/masquerade/back(.:format)
  // function(account_slug, options)
  back_employee_masquerade_index_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"masquerade",false],[2,[7,"/",false],[2,[6,"back",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// balances_api_v2_invoices => /:account_slug/api/v2/invoices/balances.:format
  // function(account_slug, format, options)
  balances_api_v2_invoices_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"invoices",false],[2,[7,"/",false],[2,[6,"balances",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// batch_print_invoices => /:account_slug/invoices/batch_print(.:format)
  // function(account_slug, options)
  batch_print_invoices_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"invoices",false],[2,[7,"/",false],[2,[6,"batch_print",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_send_invoices => /:account_slug/invoices/batch_send(.:format)
  // function(account_slug, options)
  batch_send_invoices_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"invoices",false],[2,[7,"/",false],[2,[6,"batch_send",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_update_invoices => /:account_slug/invoices/batch_update(.:format)
  // function(account_slug, options)
  batch_update_invoices_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"invoices",false],[2,[7,"/",false],[2,[6,"batch_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// batch_validate_invoices => /:account_slug/invoices/batch_validate(.:format)
  // function(account_slug, options)
  batch_validate_invoices_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"invoices",false],[2,[7,"/",false],[2,[6,"batch_validate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// billing_edit_subscription => /:account_slug/subscription/billing_edit(.:format)
  // function(account_slug, options)
  billing_edit_subscription_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"subscription",false],[2,[7,"/",false],[2,[6,"billing_edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// billing_update_subscription => /:account_slug/subscription/billing_update(.:format)
  // function(account_slug, options)
  billing_update_subscription_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"subscription",false],[2,[7,"/",false],[2,[6,"billing_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// book_request => /:account_slug/requests/:id/book(.:format)
  // function(account_slug, id, options)
  book_request_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"requests",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"book",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// calendar => /:account_slug/calendar(.:format)
  // function(account_slug, options)
  calendar_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"calendar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// calendar_dispatch => /:account_slug/calendar/dispatch(.:format)
  // function(account_slug, options)
  calendar_dispatch_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"calendar",false],[2,[7,"/",false],[2,[6,"dispatch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// calendar_dispatch_map => /:account_slug/dispatch(.:format)
  // function(account_slug, options)
  calendar_dispatch_map_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"dispatch",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// calendar_event => /:account_slug/calendar/events/:id(.:format)
  // function(account_slug, id, options)
  calendar_event_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"calendar",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// calendar_events => /:account_slug/calendar/events(.:format)
  // function(account_slug, options)
  calendar_events_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"calendar",false],[2,[7,"/",false],[2,[6,"events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// calendar_settings => /:account_slug/calendar_settings(.:format)
  // function(account_slug, options)
  calendar_settings_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"calendar_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// cancel_account => /:account_slug/settings/cancel(.:format)
  // function(account_slug, options)
  cancel_account_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cancel_api_v2_job => /:account_slug/api/v2/jobs/:id/cancel.:format
  // function(account_slug, id, format, options)
  cancel_api_v2_job_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// cancel_job => /:account_slug/jobs/:id/cancel(.:format)
  // function(account_slug, id, options)
  cancel_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// cancel_request => /:account_slug/requests/:id/cancel(.:format)
  // function(account_slug, id, options)
  cancel_request_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"requests",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// cancel_self_service_request => /:account_slug/self_service/requests/:id/cancel(.:format)
  // function(account_slug, id, options)
  cancel_self_service_request_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"requests",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// canceled_jobs => /:account_slug/jobs/canceled(.:format)
  // function(account_slug, options)
  canceled_jobs_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"canceled",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// change_account_owner_employees => /:account_slug/employees/change_account_owner(.:format)
  // function(account_slug, options)
  change_account_owner_employees_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"change_account_owner",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// change_job_number_job => /:account_slug/jobs/:id/change_job_number(.:format)
  // function(account_slug, id, options)
  change_job_number_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"change_job_number",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// change_job_number_opportunity => /:account_slug/opportunities/:id/change_job_number(.:format)
  // function(account_slug, id, options)
  change_job_number_opportunity_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"change_job_number",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// change_password_employee => /:account_slug/employees/:id/change_password(.:format)
  // function(account_slug, id, options)
  change_password_employee_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"change_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// charge_list_api_v2_items => /:account_slug/api/v2/items/charge_list.:format
  // function(account_slug, format, options)
  charge_list_api_v2_items_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"charge_list",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// charge_list_items => /:account_slug/items/charge_list(.:format)
  // function(account_slug, options)
  charge_list_items_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"charge_list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// chargify_verify => /:account_slug/chargify/verify(.:format)
  // function(account_slug, options)
  chargify_verify_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"chargify",false],[2,[7,"/",false],[2,[6,"verify",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// chargify_webhook => /app/chargify/webhook(.:format)
  // function(options)
  chargify_webhook_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"chargify",false],[2,[7,"/",false],[2,[6,"webhook",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// check_if_jobs_exist_api_v2_reports => /:account_slug/api/v2/reports/check_if_jobs_exist.:format
  // function(account_slug, format, options)
  check_if_jobs_exist_api_v2_reports_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"check_if_jobs_exist",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// checkins_map => /:account_slug/map/checkins(.:format)
  // function(account_slug, options)
  checkins_map_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"map",false],[2,[7,"/",false],[2,[6,"checkins",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// checkpoint_job => /:account_slug/jobs/:id/checkpoint(.:format)
  // function(account_slug, id, options)
  checkpoint_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"checkpoint",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// close_starter_message => /:account_slug/close_starter_message(.:format)
  // function(account_slug, options)
  close_starter_message_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"close_starter_message",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// complete_api_v2_job => /:account_slug/api/v2/jobs/:id/complete.:format
  // function(account_slug, id, format, options)
  complete_api_v2_job_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"complete",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// confirm_destroy_job => /:account_slug/jobs/:id/confirm_destroy(.:format)
  // function(account_slug, id, options)
  confirm_destroy_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"confirm_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// confirmation_self_service_request => /:account_slug/self_service/requests/:id/confirmation(.:format)
  // function(account_slug, id, options)
  confirmation_self_service_request_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"requests",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// confirmation_self_service_session => /:account_slug/self_service/session/confirmation(.:format)
  // function(account_slug, options)
  confirmation_self_service_session_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"session",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// connect => /:account_slug/connect(.:format)
  // function(account_slug, options)
  connect_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"connect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// connect_service => /:account_slug/connect/services/:id(.:format)
  // function(account_slug, id, options)
  connect_service_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"connect",false],[2,[7,"/",false],[2,[6,"services",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// connect_services => /:account_slug/connect/services(.:format)
  // function(account_slug, options)
  connect_services_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"connect",false],[2,[7,"/",false],[2,[6,"services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contact => /:account_slug/connect/contact(.:format)
  // function(account_slug, options)
  contact_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"connect",false],[2,[7,"/",false],[2,[6,"contact",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contact_path => /:account_slug/connect/contact(.:format)
  // function(account_slug, options)
  contact_path_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"connect",false],[2,[7,"/",false],[2,[6,"contact",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contact_tags => /:account_slug/tags/contact(.:format)
  // function(account_slug, options)
  contact_tags_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[6,"contact",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contacts => /:account_slug/contacts(.:format)
  // function(account_slug, options)
  contacts_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// contacts_failure => /:account_slug/contacts/failure(.:format)
  // function(account_slug, options)
  contacts_failure_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"failure",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contacts_gmail_callback => /:account_slug/contacts/gmail/callback(.:format)
  // function(account_slug, options)
  contacts_gmail_callback_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"gmail",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// contacts_gmail_confirm => /:account_slug/contacts/gmail/confirm(.:format)
  // function(account_slug, options)
  contacts_gmail_confirm_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"gmail",false],[2,[7,"/",false],[2,[6,"confirm",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// contacts_hotmail_callback => /:account_slug/contacts/hotmail/callback(.:format)
  // function(account_slug, options)
  contacts_hotmail_callback_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"hotmail",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// contacts_hotmail_confirm => /:account_slug/contacts/hotmail/confirm(.:format)
  // function(account_slug, options)
  contacts_hotmail_confirm_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"hotmail",false],[2,[7,"/",false],[2,[6,"confirm",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// contacts_yahoo_callback => /:account_slug/contacts/yahoo/callback(.:format)
  // function(account_slug, options)
  contacts_yahoo_callback_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"yahoo",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// contacts_yahoo_confirm => /:account_slug/contacts/yahoo/confirm(.:format)
  // function(account_slug, options)
  contacts_yahoo_confirm_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"yahoo",false],[2,[7,"/",false],[2,[6,"confirm",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// conversations_api_v2_customer => /:account_slug/api/v2/customers/:id/conversations.:format
  // function(account_slug, id, format, options)
  conversations_api_v2_customer_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"conversations",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// count_by_status_api_v2_jobs => /:account_slug/api/v2/jobs/count_by_status.:format
  // function(account_slug, format, options)
  count_by_status_api_v2_jobs_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"count_by_status",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// count_by_status_jobs => /:account_slug/jobs/count_by_status(.:format)
  // function(account_slug, options)
  count_by_status_jobs_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"count_by_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_account => /app/signup(.:format)
  // function(options)
  create_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"signup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// create_customer_for_request_self_service_session => /:account_slug/self_service/session/create_customer_for_request(.:format)
  // function(account_slug, options)
  create_customer_for_request_self_service_session_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"session",false],[2,[7,"/",false],[2,[6,"create_customer_for_request",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// custom_field_configuration => /:account_slug/settings/custom_field_configuration(.:format)
  // function(account_slug, options)
  custom_field_configuration_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"custom_field_configuration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// custom_fields => /:account_slug/settings/custom_fields(.:format)
  // function(account_slug, options)
  custom_fields_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"custom_fields",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer => /:account_slug/customers/:id(.:format)
  // function(account_slug, id, options)
  customer_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_attachment => /:account_slug/customers/:customer_id/attachments/:id(.:format)
  // function(account_slug, customer_id, id, options)
  customer_attachment_path: Utils.route([["account_slug",true],["customer_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// customer_attachments => /:account_slug/customers/:customer_id/attachments(.:format)
  // function(account_slug, customer_id, options)
  customer_attachments_path: Utils.route([["account_slug",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// customer_contact => /:account_slug/customers/:customer_id/contacts/:id(.:format)
  // function(account_slug, customer_id, id, options)
  customer_contact_path: Utils.route([["account_slug",true],["customer_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// customer_contacts => /:account_slug/customers/:customer_id/contacts(.:format)
  // function(account_slug, customer_id, options)
  customer_contacts_path: Utils.route([["account_slug",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// customer_job => /:account_slug/customers/:customer_id/jobs/:id(.:format)
  // function(account_slug, customer_id, id, options)
  customer_job_path: Utils.route([["account_slug",true],["customer_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// customer_jobs => /:account_slug/customers/:customer_id/jobs(.:format)
  // function(account_slug, customer_id, options)
  customer_jobs_path: Utils.route([["account_slug",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// customer_note => /:account_slug/customers/:customer_id/notes/:id(.:format)
  // function(account_slug, customer_id, id, options)
  customer_note_path: Utils.route([["account_slug",true],["customer_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// customer_notes => /:account_slug/customers/:customer_id/notes(.:format)
  // function(account_slug, customer_id, options)
  customer_notes_path: Utils.route([["account_slug",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"notes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// customer_opportunities => /:account_slug/customers/:customer_id/opportunities(.:format)
  // function(account_slug, customer_id, options)
  customer_opportunities_path: Utils.route([["account_slug",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"opportunities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// customer_opportunity => /:account_slug/customers/:customer_id/opportunities/:id(.:format)
  // function(account_slug, customer_id, id, options)
  customer_opportunity_path: Utils.route([["account_slug",true],["customer_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// customer_payment => /:account_slug/customers/:customer_id/payments/:id(.:format)
  // function(account_slug, customer_id, id, options)
  customer_payment_path: Utils.route([["account_slug",true],["customer_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// customer_payments => /:account_slug/settings/customer_payments(.:format)
  // function(account_slug, options)
  customer_payments_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"customer_payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_reminder_emails => /:account_slug/settings/customer_reminder_emails(.:format)
  // function(account_slug, options)
  customer_reminder_emails_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"customer_reminder_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_source => /:account_slug/customer_sources/:id(.:format)
  // function(account_slug, id, options)
  customer_source_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customer_sources",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_sources => /:account_slug/customer_sources(.:format)
  // function(account_slug, options)
  customer_sources_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customer_sources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// customer_tagging => /:account_slug/customers/:customer_id/taggings/:id(.:format)
  // function(account_slug, customer_id, id, options)
  customer_tagging_path: Utils.route([["account_slug",true],["customer_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"taggings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// customer_taggings => /:account_slug/customers/:customer_id/taggings(.:format)
  // function(account_slug, customer_id, options)
  customer_taggings_path: Utils.route([["account_slug",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"taggings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// customer_validation_api_v2_customers => /:account_slug/api/v2/customers/customer_validation.:format
  // function(account_slug, format, options)
  customer_validation_api_v2_customers_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"customer_validation",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// customers => /:account_slug/customers(.:format)
  // function(account_slug, options)
  customers_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// dashboard => /:account_slug/dashboard(.:format)
  // function(account_slug, options)
  dashboard_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// dashboard_dashboard_info => /:account_slug/dashboard/dashboard_info(.:format)
  // function(account_slug, options)
  dashboard_dashboard_info_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"dashboard_info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// days_unavailable => /:account_slug/employees/days_unavailable(.:format)
  // function(account_slug, options)
  days_unavailable_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"days_unavailable",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// deactivate_self_service_customer => /:account_slug/customers/:id/deactivate_self_service(.:format)
  // function(account_slug, id, options)
  deactivate_self_service_customer_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"deactivate_self_service",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// decline_request => /:account_slug/requests/:id/decline(.:format)
  // function(account_slug, id, options)
  decline_request_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"requests",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"decline",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// delete_podium_keys => /:account_slug/settings/delete_podium_keys(.:format)
  // function(account_slug, options)
  delete_podium_keys_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"delete_podium_keys",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// deliver_self_service_welcome_email_customer => /:account_slug/customers/:id/deliver_self_service_welcome_email(.:format)
  // function(account_slug, id, options)
  deliver_self_service_welcome_email_customer_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"deliver_self_service_welcome_email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// destroy_employee_session => /:account_slug/logout(.:format)
  // function(account_slug, options)
  destroy_employee_session_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"logout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// disapprove_job => /:account_slug/jobs/:id/disapprove(.:format)
  // function(account_slug, id, options)
  disapprove_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"disapprove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// disconnect_google_employees => /:account_slug/employees/disconnect_google(.:format)
  // function(account_slug, options)
  disconnect_google_employees_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"disconnect_google",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// do_not_sync_job => /:account_slug/jobs/:id/do_not_sync(.:format)
  // function(account_slug, id, options)
  do_not_sync_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"do_not_sync",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// dossier_multi_report => /multi/reports/*report(.:format)
  // function(report, options)
  dossier_multi_report_path: Utils.route([["report",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"multi",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[5,[3,"*report",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// dossier_report => /reports/*report(.:format)
  // function(report, options)
  dossier_report_path: Utils.route([["report",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[5,[3,"*report",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// dropbox_authorize => /:account_slug/dropbox/authorize(.:format)
  // function(account_slug, options)
  dropbox_authorize_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"dropbox",false],[2,[7,"/",false],[2,[6,"authorize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// dropbox_callback => /app/dropbox/callback(.:format)
  // function(options)
  dropbox_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"dropbox",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// dropbox_disconnect => /:account_slug/dropbox/disconnect(.:format)
  // function(account_slug, options)
  dropbox_disconnect_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"dropbox",false],[2,[7,"/",false],[2,[6,"disconnect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// duplicate_job => /:account_slug/jobs/:id/duplicate(.:format)
  // function(account_slug, id, options)
  duplicate_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"duplicate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// duplicate_opportunity => /:account_slug/opportunities/:id/duplicate(.:format)
  // function(account_slug, id, options)
  duplicate_opportunity_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"duplicate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_account_payment => /:account_slug/payments/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_account_payment_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_api_v2_customer => /:account_slug/api/v2/customers/:id/edit.:format
  // function(account_slug, id, format, options)
  edit_api_v2_customer_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// edit_api_v2_customer_job => /:account_slug/api/v2/customers/:customer_id/jobs/:id/edit.:format
  // function(account_slug, customer_id, id, format, options)
  edit_api_v2_customer_job_path: Utils.route([["account_slug",true],["customer_id",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]]]),
// edit_api_v2_customer_note => /:account_slug/api/v2/customers/:customer_id/notes/:id/edit.:format
  // function(account_slug, customer_id, id, format, options)
  edit_api_v2_customer_note_path: Utils.route([["account_slug",true],["customer_id",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]]]),
// edit_api_v2_customer_payment => /:account_slug/api/v2/customers/:customer_id/payments/:id/edit.:format
  // function(account_slug, customer_id, id, format, options)
  edit_api_v2_customer_payment_path: Utils.route([["account_slug",true],["customer_id",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]]]),
// edit_api_v2_item => /:account_slug/api/v2/items/:id/edit.:format
  // function(account_slug, id, format, options)
  edit_api_v2_item_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// edit_api_v2_job => /:account_slug/api/v2/jobs/:id/edit.:format
  // function(account_slug, id, format, options)
  edit_api_v2_job_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// edit_api_v2_job_charge_type => /:account_slug/api/v2/job_charge_types/:id/edit.:format
  // function(account_slug, id, format, options)
  edit_api_v2_job_charge_type_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"job_charge_types",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// edit_api_v2_job_expense => /:account_slug/api/v2/jobs/:job_id/expenses/:id/edit.:format
  // function(account_slug, job_id, id, format, options)
  edit_api_v2_job_expense_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"expenses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]]]),
// edit_api_v2_job_note => /:account_slug/api/v2/jobs/:job_id/notes/:id/edit.:format
  // function(account_slug, job_id, id, format, options)
  edit_api_v2_job_note_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]]]),
// edit_api_v2_job_payment => /:account_slug/api/v2/jobs/:job_id/payments/:id/edit.:format
  // function(account_slug, job_id, id, format, options)
  edit_api_v2_job_payment_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]]]),
// edit_api_v2_job_task => /:account_slug/api/v2/jobs/:job_id/tasks/:id/edit.:format
  // function(account_slug, job_id, id, format, options)
  edit_api_v2_job_task_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]]]),
// edit_api_v2_note => /:account_slug/api/v2/notes/:id/edit.:format
  // function(account_slug, id, format, options)
  edit_api_v2_note_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// edit_api_v2_opportunity => /:account_slug/api/v2/opportunities/:id/edit.:format
  // function(account_slug, id, format, options)
  edit_api_v2_opportunity_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// edit_api_v2_payment => /:account_slug/api/v2/payments/:id/edit.:format
  // function(account_slug, id, format, options)
  edit_api_v2_payment_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// edit_api_v2_signature => /:account_slug/api/v2/signatures/:id/edit.:format
  // function(account_slug, id, format, options)
  edit_api_v2_signature_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"signatures",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// edit_api_v2_task => /:account_slug/api/v2/tasks/:id/edit.:format
  // function(account_slug, id, format, options)
  edit_api_v2_task_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// edit_calendar_event => /:account_slug/calendar/events/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_calendar_event_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"calendar",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_customer => /:account_slug/customers/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_customer_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_customer_attachment => /:account_slug/customers/:customer_id/attachments/:id/edit(.:format)
  // function(account_slug, customer_id, id, options)
  edit_customer_attachment_path: Utils.route([["account_slug",true],["customer_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_customer_contact => /:account_slug/customers/:customer_id/contacts/:id/edit(.:format)
  // function(account_slug, customer_id, id, options)
  edit_customer_contact_path: Utils.route([["account_slug",true],["customer_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_customer_job => /:account_slug/customers/:customer_id/jobs/:id/edit(.:format)
  // function(account_slug, customer_id, id, options)
  edit_customer_job_path: Utils.route([["account_slug",true],["customer_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_customer_note => /:account_slug/customers/:customer_id/notes/:id/edit(.:format)
  // function(account_slug, customer_id, id, options)
  edit_customer_note_path: Utils.route([["account_slug",true],["customer_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_customer_opportunity => /:account_slug/customers/:customer_id/opportunities/:id/edit(.:format)
  // function(account_slug, customer_id, id, options)
  edit_customer_opportunity_path: Utils.route([["account_slug",true],["customer_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_customer_payment => /:account_slug/customers/:customer_id/payments/:id/edit(.:format)
  // function(account_slug, customer_id, id, options)
  edit_customer_payment_path: Utils.route([["account_slug",true],["customer_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_customer_source => /:account_slug/customer_sources/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_customer_source_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customer_sources",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_employee => /:account_slug/employees/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_employee_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_employee_password => /:account_slug/reset_password(.:format)
  // function(account_slug, options)
  edit_employee_password_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"reset_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_item => /:account_slug/items/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_item_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_job => /:account_slug/jobs/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_job_attachment => /:account_slug/jobs/:job_id/attachments/:id/edit(.:format)
  // function(account_slug, job_id, id, options)
  edit_job_attachment_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_job_charge_type => /:account_slug/job_charge_types/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_job_charge_type_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"job_charge_types",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_job_expense => /:account_slug/jobs/:job_id/expenses/:id/edit(.:format)
  // function(account_slug, job_id, id, options)
  edit_job_expense_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"expenses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_job_job_charge => /:account_slug/jobs/:job_id/job_charges/:id/edit(.:format)
  // function(account_slug, job_id, id, options)
  edit_job_job_charge_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"job_charges",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_job_note => /:account_slug/jobs/:job_id/notes/:id/edit(.:format)
  // function(account_slug, job_id, id, options)
  edit_job_note_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_job_payment => /:account_slug/jobs/:job_id/payments/:id/edit(.:format)
  // function(account_slug, job_id, id, options)
  edit_job_payment_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_job_status => /:account_slug/job_statuses/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_job_status_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"job_statuses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_job_task => /:account_slug/jobs/:job_id/tasks/:id/edit(.:format)
  // function(account_slug, job_id, id, options)
  edit_job_task_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_job_time_entry => /:account_slug/jobs/:job_id/time_entries/:id/edit(.:format)
  // function(account_slug, job_id, id, options)
  edit_job_time_entry_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"time_entries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_job_type => /:account_slug/job_types/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_job_type_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"job_types",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_location => /:account_slug/locations/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_location_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_logo => /:account_slug/settings/logo/edit(.:format)
  // function(account_slug, options)
  edit_logo_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"logo",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_map => /:account_slug/map/edit(.:format)
  // function(account_slug, options)
  edit_map_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"map",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_note => /:account_slug/notes/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_note_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_oauth_application => /:account_slug/oauth/applications/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_oauth_application_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_opportunity => /:account_slug/opportunities/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_opportunity_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_opportunity_attachment => /:account_slug/opportunities/:opportunity_id/attachments/:id/edit(.:format)
  // function(account_slug, opportunity_id, id, options)
  edit_opportunity_attachment_path: Utils.route([["account_slug",true],["opportunity_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"opportunity_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_payment => /:account_slug/payments/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_payment_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_post => /:account_slug/notifications/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_post_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_report => /:account_slug/reports/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_report_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_schedule_task => /:account_slug/schedule/tasks/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_schedule_task_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"schedule",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_search => /:account_slug/:search_type/searches/:id/edit(.:format)
  // function(account_slug, search_type, id, options)
  edit_search_path: Utils.route([["account_slug",true],["search_type",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[3,"search_type",false],[2,[7,"/",false],[2,[6,"searches",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_self_service_customer => /:account_slug/self_service/customers/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_self_service_customer_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_self_service_job => /:account_slug/self_service/jobs/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_self_service_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_self_service_session => /:account_slug/self_service/session/edit(.:format)
  // function(account_slug, options)
  edit_self_service_session_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"session",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_setup_marketing => /:account_slug/setup_marketing/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_setup_marketing_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"setup_marketing",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_signature => /:account_slug/signatures/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_signature_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"signatures",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_subscription => /:account_slug/subscription/edit(.:format)
  // function(account_slug, options)
  edit_subscription_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"subscription",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_sync_error => /:account_slug/sync_errors/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_sync_error_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"sync_errors",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_tag => /:account_slug/tags/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_tag_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_task => /:account_slug/tasks/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_task_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_tax => /:account_slug/taxes/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_tax_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"taxes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_tech => /:account_slug/techs/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_tech_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"techs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_vendor => /:account_slug/vendors/:id/edit(.:format)
  // function(account_slug, id, options)
  edit_vendor_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"vendors",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// email_activity => /:account_slug/email_activity(.:format)
  // function(account_slug, options)
  email_activity_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"email_activity",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// email_template_api_v2_job => /:account_slug/api/v2/jobs/:id/email_template.:format
  // function(account_slug, id, format, options)
  email_template_api_v2_job_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"email_template",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// email_template_job => /:account_slug/jobs/:id/email_template(.:format)
  // function(account_slug, id, options)
  email_template_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"email_template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// embed_portal => /:account_slug/embed_portal(.:format)
  // function(account_slug, options)
  embed_portal_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"embed_portal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// employee => /:account_slug/employees/:id(.:format)
  // function(account_slug, id, options)
  employee_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_employee_settings => /:account_slug/employees/:employee_id/employee_settings(.:format)
  // function(account_slug, employee_id, options)
  employee_employee_settings_path: Utils.route([["account_slug",true],["employee_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"employee_id",false],[2,[7,"/",false],[2,[6,"employee_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee_masquerade => /:account_slug/employees/masquerade/:id(.:format)
  // function(account_slug, id, options)
  employee_masquerade_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"masquerade",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// employee_password => /:account_slug/recover_password(.:format)
  // function(account_slug, options)
  employee_password_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"recover_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// employee_session => /:account_slug/login(.:format)
  // function(account_slug, options)
  employee_session_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// employee_setup => /:account_slug/employee_setup/:token(.:format)
  // function(account_slug, token, options)
  employee_setup_path: Utils.route([["account_slug",true],["token",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employee_setup",false],[2,[7,"/",false],[2,[3,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_unavailability => /:account_slug/employees/unavailable_hours(.:format)
  // function(account_slug, options)
  employee_unavailability_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"unavailable_hours",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employee_unlock => /:account_slug/employees/unlock(.:format)
  // function(account_slug, options)
  employee_unlock_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"unlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// employees => /:account_slug/employees(.:format)
  // function(account_slug, options)
  employees_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// error_self_service_session => /:account_slug/self_service/session/error(.:format)
  // function(account_slug, options)
  error_self_service_session_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"session",false],[2,[7,"/",false],[2,[6,"error",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// estimate_approve_self_service_job => /:account_slug/self_service/jobs/:id/estimate/approve(.:format)
  // function(account_slug, id, options)
  estimate_approve_self_service_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"estimate",false],[2,[7,"/",false],[2,[6,"approve",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// estimate_confirm_api_v2_job => /:account_slug/api/v2/jobs/:id/estimate_confirm.:format
  // function(account_slug, id, format, options)
  estimate_confirm_api_v2_job_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"estimate_confirm",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// estimate_confirm_job => /:account_slug/jobs/:id/estimate_confirm(.:format)
  // function(account_slug, id, options)
  estimate_confirm_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"estimate_confirm",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// estimate_confirm_opportunity => /:account_slug/opportunities/:id/estimate_confirm(.:format)
  // function(account_slug, id, options)
  estimate_confirm_opportunity_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"estimate_confirm",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// estimate_emails => /:account_slug/settings/estimate_emails(.:format)
  // function(account_slug, options)
  estimate_emails_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"estimate_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// estimate_job => /:account_slug/jobs/:id/estimate(.:format)
  // function(account_slug, id, options)
  estimate_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"estimate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// estimate_opportunity => /:account_slug/opportunities/:id/estimate(.:format)
  // function(account_slug, id, options)
  estimate_opportunity_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"estimate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// estimate_self_service_job => /:account_slug/self_service/jobs/:id/estimate(.:format)
  // function(account_slug, id, options)
  estimate_self_service_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"estimate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// expired_trial => /:account_slug/expired_trial(.:format)
  // function(account_slug, options)
  expired_trial_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"expired_trial",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// export => /:account_slug/settings/export(.:format)
  // function(account_slug, options)
  export_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// export_list_customers => /:account_slug/customers/export_list(.:format)
  // function(account_slug, options)
  export_list_customers_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"export_list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// filter_api_v2_customers => /:account_slug/api/v2/customers/filter.:format
  // function(account_slug, format, options)
  filter_api_v2_customers_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"filter",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// filter_customers => /:account_slug/customers/filter(.:format)
  // function(account_slug, options)
  filter_customers_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"filter",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// franchises => /:account_slug/franchises(.:format)
  // function(account_slug, options)
  franchises_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"franchises",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// franchises_franchisees => /:account_slug/franchises/franchisees(.:format)
  // function(account_slug, options)
  franchises_franchisees_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"franchises",false],[2,[7,"/",false],[2,[6,"franchisees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// gcal_sync_calendar => /:account_slug/calendar/gcal/sync(.:format)
  // function(account_slug, options)
  gcal_sync_calendar_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"calendar",false],[2,[7,"/",false],[2,[6,"gcal",false],[2,[7,"/",false],[2,[6,"sync",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// gdpr => /:account_slug/gdpr(.:format)
  // function(account_slug, options)
  gdpr_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"gdpr",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// general => /:account_slug/settings/general(.:format)
  // function(account_slug, options)
  general_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"general",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// google_oauth_callback => /:account_slug/oauth/google/callback(.:format)
  // function(account_slug, options)
  google_oauth_callback_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"google",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// graphiql_rails => /graphiql
  // function(options)
  graphiql_rails_path: Utils.route([], {}, [2,[7,"/",false],[6,"graphiql",false]]),
// graphql => /graphql(.:format)
  // function(options)
  graphql_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"graphql",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// hide_notifications => /:account_slug/hide_notifications(.:format)
  // function(account_slug, options)
  hide_notifications_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"hide_notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// hold_api_v2_job => /:account_slug/api/v2/jobs/:id/hold.:format
  // function(account_slug, id, format, options)
  hold_api_v2_job_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"hold",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// hold_job => /:account_slug/jobs/:id/hold(.:format)
  // function(account_slug, id, options)
  hold_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"hold",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// ical_activity => /:account_slug/ical/activity/:ical_hash.:format
  // function(account_slug, ical_hash, format, options)
  ical_activity_path: Utils.route([["account_slug",true],["ical_hash",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"ical",false],[2,[7,"/",false],[2,[6,"activity",false],[2,[7,"/",false],[2,[3,"ical_hash",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// ical_all_tasks => /:account_slug/ical/all_tasks/:ical_hash.:format
  // function(account_slug, ical_hash, format, options)
  ical_all_tasks_path: Utils.route([["account_slug",true],["ical_hash",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"ical",false],[2,[7,"/",false],[2,[6,"all_tasks",false],[2,[7,"/",false],[2,[3,"ical_hash",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// ical_jobs => /:account_slug/ical/jobs/:ical_hash.:format
  // function(account_slug, ical_hash, format, options)
  ical_jobs_path: Utils.route([["account_slug",true],["ical_hash",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"ical",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"ical_hash",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// ical_public_tasks => /:account_slug/ical/public_tasks/:ical_hash.:format
  // function(account_slug, ical_hash, format, options)
  ical_public_tasks_path: Utils.route([["account_slug",true],["ical_hash",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"ical",false],[2,[7,"/",false],[2,[6,"public_tasks",false],[2,[7,"/",false],[2,[3,"ical_hash",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]),
// import_customers => /:account_slug/customers/import(.:format)
  // function(account_slug, options)
  import_customers_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// import_items => /:account_slug/items/import(.:format)
  // function(account_slug, options)
  import_items_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// inbox_conversation => /:account_slug/inbox/conversations/:id(.:format)
  // function(account_slug, id, options)
  inbox_conversation_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[6,"conversations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// inbox_conversation_messages => /:account_slug/inbox/conversations/:conversation_id/messages(.:format)
  // function(account_slug, conversation_id, options)
  inbox_conversation_messages_path: Utils.route([["account_slug",true],["conversation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[6,"conversations",false],[2,[7,"/",false],[2,[3,"conversation_id",false],[2,[7,"/",false],[2,[6,"messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// inbox_conversations => /:account_slug/inbox/conversations(.:format)
  // function(account_slug, options)
  inbox_conversations_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[6,"conversations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// inbox_messages => /:account_slug/inbox/messages(.:format)
  // function(account_slug, options)
  inbox_messages_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[6,"messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// inbox_root => /:account_slug/inbox(.:format)
  // function(account_slug, options)
  inbox_root_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"inbox",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// inbox_unread => /:account_slug/inbox/unread(.:format)
  // function(account_slug, options)
  inbox_unread_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[6,"unread",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// intuit_callback => /:account_slug/intuit/callback(.:format)
  // function(account_slug, options)
  intuit_callback_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"intuit",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// intuit_connect => /:account_slug/intuit/connect(.:format)
  // function(account_slug, options)
  intuit_connect_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"intuit",false],[2,[7,"/",false],[2,[6,"connect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// intuit_disconnect => /:account_slug/intuit/disconnect(.:format)
  // function(account_slug, options)
  intuit_disconnect_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"intuit",false],[2,[7,"/",false],[2,[6,"disconnect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// intuit_landing => /:account_slug/intuit/landing(.:format)
  // function(account_slug, options)
  intuit_landing_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"intuit",false],[2,[7,"/",false],[2,[6,"landing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// intuit_openid_callback => /:account_slug/intuit/openid_callback(.:format)
  // function(account_slug, options)
  intuit_openid_callback_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"intuit",false],[2,[7,"/",false],[2,[6,"openid_callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// invite_employee => /:account_slug/employees/:id/invite(.:format)
  // function(account_slug, id, options)
  invite_employee_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"invite",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// invoice_confirm_api_v2_job => /:account_slug/api/v2/jobs/:id/invoice_confirm.:format
  // function(account_slug, id, format, options)
  invoice_confirm_api_v2_job_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"invoice_confirm",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// invoice_confirm_api_v2_opportunity => /:account_slug/api/v2/opportunities/:id/invoice_confirm.:format
  // function(account_slug, id, format, options)
  invoice_confirm_api_v2_opportunity_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"invoice_confirm",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// invoice_confirm_job => /:account_slug/jobs/:id/invoice_confirm(.:format)
  // function(account_slug, id, options)
  invoice_confirm_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"invoice_confirm",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// invoice_confirm_opportunity => /:account_slug/opportunities/:id/invoice_confirm(.:format)
  // function(account_slug, id, options)
  invoice_confirm_opportunity_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"invoice_confirm",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// invoice_emails => /:account_slug/settings/invoice_emails(.:format)
  // function(account_slug, options)
  invoice_emails_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"invoice_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// invoice_job => /:account_slug/jobs/:id/invoice(.:format)
  // function(account_slug, id, options)
  invoice_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"invoice",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// invoice_opportunity => /:account_slug/opportunities/:id/invoice(.:format)
  // function(account_slug, id, options)
  invoice_opportunity_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"invoice",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// invoice_self_service_job => /:account_slug/self_service/jobs/:id/invoice(.:format)
  // function(account_slug, id, options)
  invoice_self_service_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"invoice",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// invoices => /:account_slug/invoices(.:format)
  // function(account_slug, options)
  invoices_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"invoices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// item => /:account_slug/items/:id(.:format)
  // function(account_slug, id, options)
  item_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// items => /:account_slug/items(.:format)
  // function(account_slug, options)
  items_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// job => /:account_slug/jobs/:id(.:format)
  // function(account_slug, id, options)
  job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// job_attachment => /:account_slug/jobs/:job_id/attachments/:id(.:format)
  // function(account_slug, job_id, id, options)
  job_attachment_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// job_attachments => /:account_slug/jobs/:job_id/attachments(.:format)
  // function(account_slug, job_id, options)
  job_attachments_path: Utils.route([["account_slug",true],["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// job_charge_type => /:account_slug/job_charge_types/:id(.:format)
  // function(account_slug, id, options)
  job_charge_type_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"job_charge_types",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// job_charge_types => /:account_slug/job_charge_types(.:format)
  // function(account_slug, options)
  job_charge_types_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"job_charge_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// job_expense => /:account_slug/jobs/:job_id/expenses/:id(.:format)
  // function(account_slug, job_id, id, options)
  job_expense_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"expenses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// job_expenses => /:account_slug/jobs/:job_id/expenses(.:format)
  // function(account_slug, job_id, options)
  job_expenses_path: Utils.route([["account_slug",true],["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"expenses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// job_job_charge => /:account_slug/jobs/:job_id/job_charges/:id(.:format)
  // function(account_slug, job_id, id, options)
  job_job_charge_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"job_charges",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// job_job_charges => /:account_slug/jobs/:job_id/job_charges(.:format)
  // function(account_slug, job_id, options)
  job_job_charges_path: Utils.route([["account_slug",true],["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"job_charges",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// job_list_api_v2_tasks => /:account_slug/api/v2/tasks/job_list.:format
  // function(account_slug, format, options)
  job_list_api_v2_tasks_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[6,"job_list",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// job_note => /:account_slug/jobs/:job_id/notes/:id(.:format)
  // function(account_slug, job_id, id, options)
  job_note_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// job_notes => /:account_slug/jobs/:job_id/notes(.:format)
  // function(account_slug, job_id, options)
  job_notes_path: Utils.route([["account_slug",true],["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"notes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// job_payment => /:account_slug/jobs/:job_id/payments/:id(.:format)
  // function(account_slug, job_id, id, options)
  job_payment_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// job_payments => /:account_slug/jobs/:job_id/payments(.:format)
  // function(account_slug, job_id, options)
  job_payments_path: Utils.route([["account_slug",true],["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// job_status => /:account_slug/job_statuses/:id(.:format)
  // function(account_slug, id, options)
  job_status_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"job_statuses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// job_statuses => /:account_slug/job_statuses(.:format)
  // function(account_slug, options)
  job_statuses_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"job_statuses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// job_tagging => /:account_slug/jobs/:job_id/taggings/:id(.:format)
  // function(account_slug, job_id, id, options)
  job_tagging_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"taggings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// job_taggings => /:account_slug/jobs/:job_id/taggings(.:format)
  // function(account_slug, job_id, options)
  job_taggings_path: Utils.route([["account_slug",true],["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"taggings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// job_task => /:account_slug/jobs/:job_id/tasks/:id(.:format)
  // function(account_slug, job_id, id, options)
  job_task_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// job_tasks => /:account_slug/jobs/:job_id/tasks(.:format)
  // function(account_slug, job_id, options)
  job_tasks_path: Utils.route([["account_slug",true],["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"tasks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// job_time_entries => /:account_slug/jobs/:job_id/time_entries(.:format)
  // function(account_slug, job_id, options)
  job_time_entries_path: Utils.route([["account_slug",true],["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"time_entries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// job_time_entry => /:account_slug/jobs/:job_id/time_entries/:id(.:format)
  // function(account_slug, job_id, id, options)
  job_time_entry_path: Utils.route([["account_slug",true],["job_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"time_entries",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// job_type => /:account_slug/job_types/:id(.:format)
  // function(account_slug, id, options)
  job_type_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"job_types",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// job_types => /:account_slug/job_types(.:format)
  // function(account_slug, options)
  job_types_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"job_types",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// jobs => /:account_slug/jobs(.:format)
  // function(account_slug, options)
  jobs_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// listings_and_reputation_management_settings => /:account_slug/settings/listings_and_reputation_management(.:format)
  // function(account_slug, options)
  listings_and_reputation_management_settings_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"listings_and_reputation_management",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// location => /:account_slug/locations/:id(.:format)
  // function(account_slug, id, options)
  location_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// locations => /:account_slug/locations(.:format)
  // function(account_slug, options)
  locations_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"locations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// login => /app/login(.:format)
  // function(options)
  login_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// logo => /:account_slug/settings/logo(.:format)
  // function(account_slug, options)
  logo_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"logo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// logo_upload => /:account_slug/logos/upload_logo(.:format)
  // function(account_slug, options)
  logo_upload_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"logos",false],[2,[7,"/",false],[2,[6,"upload_logo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lost_password_self_service_session => /:account_slug/self_service/session/lost_password(.:format)
  // function(account_slug, options)
  lost_password_self_service_session_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"session",false],[2,[7,"/",false],[2,[6,"lost_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// map => /:account_slug/map(.:format)
  // function(account_slug, options)
  map_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"map",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// map_api_v3_employees => /:account_slug/api/v3/employees/map.:format
  // function(account_slug, format, options)
  map_api_v3_employees_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"map",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// mark_all_posts_read_posts => /:account_slug/notifications/mark_all_posts_read(.:format)
  // function(account_slug, options)
  mark_all_posts_read_posts_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[6,"mark_all_posts_read",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// mark_as_lost_api_v2_opportunity => /:account_slug/api/v2/opportunities/:id/mark_as_lost.:format
  // function(account_slug, id, format, options)
  mark_as_lost_api_v2_opportunity_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"mark_as_lost",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// mark_as_lost_opportunity => /:account_slug/opportunities/:id/mark_as_lost(.:format)
  // function(account_slug, id, options)
  mark_as_lost_opportunity_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"mark_as_lost",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// mark_as_new_api_v2_job => /:account_slug/api/v2/jobs/:id/mark_as_new.:format
  // function(account_slug, id, format, options)
  mark_as_new_api_v2_job_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"mark_as_new",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// mark_as_new_api_v2_opportunity => /:account_slug/api/v2/opportunities/:id/mark_as_new.:format
  // function(account_slug, id, format, options)
  mark_as_new_api_v2_opportunity_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"mark_as_new",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// mark_as_new_opportunity => /:account_slug/opportunities/:id/mark_as_new(.:format)
  // function(account_slug, id, options)
  mark_as_new_opportunity_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"mark_as_new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// mark_as_won_api_v2_opportunity => /:account_slug/api/v2/opportunities/:id/mark_as_won.:format
  // function(account_slug, id, format, options)
  mark_as_won_api_v2_opportunity_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"mark_as_won",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// mark_as_won_opportunity => /:account_slug/opportunities/:id/mark_as_won(.:format)
  // function(account_slug, id, options)
  mark_as_won_opportunity_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"mark_as_won",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// mark_complete_api_v3_task => /:account_slug/api/v3/tasks/:id/mark_complete.:format
  // function(account_slug, id, format, options)
  mark_complete_api_v3_task_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"mark_complete",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// mark_complete_job => /:account_slug/jobs/:id/mark_complete(.:format)
  // function(account_slug, id, options)
  mark_complete_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"mark_complete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// mark_paid_api_v2_job => /:account_slug/api/v2/jobs/:id/mark_paid.:format
  // function(account_slug, id, format, options)
  mark_paid_api_v2_job_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"mark_paid",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// mark_paid_job => /:account_slug/jobs/:id/mark_paid(.:format)
  // function(account_slug, id, options)
  mark_paid_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"mark_paid",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// mark_read_inbox_conversations => /:account_slug/inbox/conversations/mark_read(.:format)
  // function(account_slug, options)
  mark_read_inbox_conversations_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[6,"conversations",false],[2,[7,"/",false],[2,[6,"mark_read",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// mark_read_self_service_inbox_conversations => /:account_slug/self_service/inbox/conversations/mark_read(.:format)
  // function(account_slug, options)
  mark_read_self_service_inbox_conversations_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[6,"conversations",false],[2,[7,"/",false],[2,[6,"mark_read",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// mark_unread_inbox_conversations => /:account_slug/inbox/conversations/mark_unread(.:format)
  // function(account_slug, options)
  mark_unread_inbox_conversations_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[6,"conversations",false],[2,[7,"/",false],[2,[6,"mark_unread",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// mark_unread_self_service_inbox_conversations => /:account_slug/self_service/inbox/conversations/mark_unread(.:format)
  // function(account_slug, options)
  mark_unread_self_service_inbox_conversations_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[6,"conversations",false],[2,[7,"/",false],[2,[6,"mark_unread",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// merge_customer => /:account_slug/customers/:id/merge(.:format)
  // function(account_slug, id, options)
  merge_customer_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"merge",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// native_oauth_authorization => /:account_slug/oauth/authorize/native(.:format)
  // function(account_slug, options)
  native_oauth_authorization_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorize",false],[2,[7,"/",false],[2,[6,"native",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_api_v2_customer => /:account_slug/api/v2/customers/new.:format
  // function(account_slug, format, options)
  new_api_v2_customer_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"new",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// new_api_v2_customer_job => /:account_slug/api/v2/customers/:customer_id/jobs/new.:format
  // function(account_slug, customer_id, format, options)
  new_api_v2_customer_job_path: Utils.route([["account_slug",true],["customer_id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"new",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]),
// new_api_v2_customer_note => /:account_slug/api/v2/customers/:customer_id/notes/new.:format
  // function(account_slug, customer_id, format, options)
  new_api_v2_customer_note_path: Utils.route([["account_slug",true],["customer_id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[6,"new",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]),
// new_api_v2_customer_payment => /:account_slug/api/v2/customers/:customer_id/payments/new.:format
  // function(account_slug, customer_id, format, options)
  new_api_v2_customer_payment_path: Utils.route([["account_slug",true],["customer_id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"new",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]),
// new_api_v2_item => /:account_slug/api/v2/items/new.:format
  // function(account_slug, format, options)
  new_api_v2_item_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"new",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// new_api_v2_job => /:account_slug/api/v2/jobs/new.:format
  // function(account_slug, format, options)
  new_api_v2_job_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"new",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// new_api_v2_job_charge_type => /:account_slug/api/v2/job_charge_types/new.:format
  // function(account_slug, format, options)
  new_api_v2_job_charge_type_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"job_charge_types",false],[2,[7,"/",false],[2,[6,"new",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// new_api_v2_job_expense => /:account_slug/api/v2/jobs/:job_id/expenses/new.:format
  // function(account_slug, job_id, format, options)
  new_api_v2_job_expense_path: Utils.route([["account_slug",true],["job_id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"expenses",false],[2,[7,"/",false],[2,[6,"new",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]),
// new_api_v2_job_note => /:account_slug/api/v2/jobs/:job_id/notes/new.:format
  // function(account_slug, job_id, format, options)
  new_api_v2_job_note_path: Utils.route([["account_slug",true],["job_id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[6,"new",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]),
// new_api_v2_job_payment => /:account_slug/api/v2/jobs/:job_id/payments/new.:format
  // function(account_slug, job_id, format, options)
  new_api_v2_job_payment_path: Utils.route([["account_slug",true],["job_id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"new",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]),
// new_api_v2_job_task => /:account_slug/api/v2/jobs/:job_id/tasks/new.:format
  // function(account_slug, job_id, format, options)
  new_api_v2_job_task_path: Utils.route([["account_slug",true],["job_id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[6,"new",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]]]),
// new_api_v2_note => /:account_slug/api/v2/notes/new.:format
  // function(account_slug, format, options)
  new_api_v2_note_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[6,"new",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// new_api_v2_opportunity => /:account_slug/api/v2/opportunities/new.:format
  // function(account_slug, format, options)
  new_api_v2_opportunity_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[6,"new",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// new_api_v2_payment => /:account_slug/api/v2/payments/new.:format
  // function(account_slug, format, options)
  new_api_v2_payment_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"new",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// new_api_v2_signature => /:account_slug/api/v2/signatures/new.:format
  // function(account_slug, format, options)
  new_api_v2_signature_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"signatures",false],[2,[7,"/",false],[2,[6,"new",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// new_api_v2_task => /:account_slug/api/v2/tasks/new.:format
  // function(account_slug, format, options)
  new_api_v2_task_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[6,"new",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// new_calendar_event => /:account_slug/calendar/events/new(.:format)
  // function(account_slug, options)
  new_calendar_event_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"calendar",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_customer => /:account_slug/customers/new(.:format)
  // function(account_slug, options)
  new_customer_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_customer_attachment => /:account_slug/customers/:customer_id/attachments/new(.:format)
  // function(account_slug, customer_id, options)
  new_customer_attachment_path: Utils.route([["account_slug",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_customer_contact => /:account_slug/customers/:customer_id/contacts/new(.:format)
  // function(account_slug, customer_id, options)
  new_customer_contact_path: Utils.route([["account_slug",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_customer_job => /:account_slug/customers/:customer_id/jobs/new(.:format)
  // function(account_slug, customer_id, options)
  new_customer_job_path: Utils.route([["account_slug",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_customer_note => /:account_slug/customers/:customer_id/notes/new(.:format)
  // function(account_slug, customer_id, options)
  new_customer_note_path: Utils.route([["account_slug",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_customer_opportunity => /:account_slug/customers/:customer_id/opportunities/new(.:format)
  // function(account_slug, customer_id, options)
  new_customer_opportunity_path: Utils.route([["account_slug",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_customer_payment => /:account_slug/customers/:customer_id/payments/new(.:format)
  // function(account_slug, customer_id, options)
  new_customer_payment_path: Utils.route([["account_slug",true],["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_customer_source => /:account_slug/customer_sources/new(.:format)
  // function(account_slug, options)
  new_customer_source_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customer_sources",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_employee => /:account_slug/employees/new(.:format)
  // function(account_slug, options)
  new_employee_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_employee_password => /:account_slug/recover_password(.:format)
  // function(account_slug, options)
  new_employee_password_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"recover_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_employee_session => /:account_slug/login(.:format)
  // function(account_slug, options)
  new_employee_session_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_employee_unlock => /:account_slug/employees/unlock/new(.:format)
  // function(account_slug, options)
  new_employee_unlock_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"unlock",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_inbox_conversation => /:account_slug/inbox/conversations/new(.:format)
  // function(account_slug, options)
  new_inbox_conversation_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[6,"conversations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_item => /:account_slug/items/new(.:format)
  // function(account_slug, options)
  new_item_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_job => /:account_slug/jobs/new(.:format)
  // function(account_slug, options)
  new_job_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_job_attachment => /:account_slug/jobs/:job_id/attachments/new(.:format)
  // function(account_slug, job_id, options)
  new_job_attachment_path: Utils.route([["account_slug",true],["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_job_charge_type => /:account_slug/job_charge_types/new(.:format)
  // function(account_slug, options)
  new_job_charge_type_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"job_charge_types",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_job_expense => /:account_slug/jobs/:job_id/expenses/new(.:format)
  // function(account_slug, job_id, options)
  new_job_expense_path: Utils.route([["account_slug",true],["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"expenses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_job_job_charge => /:account_slug/jobs/:job_id/job_charges/new(.:format)
  // function(account_slug, job_id, options)
  new_job_job_charge_path: Utils.route([["account_slug",true],["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"job_charges",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_job_note => /:account_slug/jobs/:job_id/notes/new(.:format)
  // function(account_slug, job_id, options)
  new_job_note_path: Utils.route([["account_slug",true],["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_job_payment => /:account_slug/jobs/:job_id/payments/new(.:format)
  // function(account_slug, job_id, options)
  new_job_payment_path: Utils.route([["account_slug",true],["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_job_status => /:account_slug/job_statuses/new(.:format)
  // function(account_slug, options)
  new_job_status_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"job_statuses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_job_task => /:account_slug/jobs/:job_id/tasks/new(.:format)
  // function(account_slug, job_id, options)
  new_job_task_path: Utils.route([["account_slug",true],["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_job_time_entry => /:account_slug/jobs/:job_id/time_entries/new(.:format)
  // function(account_slug, job_id, options)
  new_job_time_entry_path: Utils.route([["account_slug",true],["job_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"job_id",false],[2,[7,"/",false],[2,[6,"time_entries",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_job_type => /:account_slug/job_types/new(.:format)
  // function(account_slug, options)
  new_job_type_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"job_types",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_location => /:account_slug/locations/new(.:format)
  // function(account_slug, options)
  new_location_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_logo => /:account_slug/settings/logo/new(.:format)
  // function(account_slug, options)
  new_logo_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"logo",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_map => /:account_slug/map/new(.:format)
  // function(account_slug, options)
  new_map_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"map",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_note => /:account_slug/notes/new(.:format)
  // function(account_slug, options)
  new_note_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_oauth_application => /:account_slug/oauth/applications/new(.:format)
  // function(account_slug, options)
  new_oauth_application_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_opportunity => /:account_slug/opportunities/new(.:format)
  // function(account_slug, options)
  new_opportunity_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_opportunity_attachment => /:account_slug/opportunities/:opportunity_id/attachments/new(.:format)
  // function(account_slug, opportunity_id, options)
  new_opportunity_attachment_path: Utils.route([["account_slug",true],["opportunity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"opportunity_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_payment => /:account_slug/payments/new(.:format)
  // function(account_slug, options)
  new_payment_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_post => /:account_slug/notifications/new(.:format)
  // function(account_slug, options)
  new_post_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_report => /:account_slug/reports/new(.:format)
  // function(account_slug, options)
  new_report_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_schedule_task => /:account_slug/schedule/tasks/new(.:format)
  // function(account_slug, options)
  new_schedule_task_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"schedule",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_search => /:account_slug/:search_type/searches/new(.:format)
  // function(account_slug, search_type, options)
  new_search_path: Utils.route([["account_slug",true],["search_type",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[3,"search_type",false],[2,[7,"/",false],[2,[6,"searches",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_self_service_customer => /:account_slug/self_service/customers/new(.:format)
  // function(account_slug, options)
  new_self_service_customer_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_self_service_job => /:account_slug/self_service/jobs/new(.:format)
  // function(account_slug, options)
  new_self_service_job_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_self_service_request => /:account_slug/self_service/requests/new(.:format)
  // function(account_slug, options)
  new_self_service_request_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"requests",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_self_service_session => /:account_slug/self_service/session/new(.:format)
  // function(account_slug, options)
  new_self_service_session_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"session",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_service_request => /:account_slug/service_requests/new(.:format)
  // function(account_slug, options)
  new_service_request_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"service_requests",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_setup_marketing => /:account_slug/setup_marketing/new(.:format)
  // function(account_slug, options)
  new_setup_marketing_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"setup_marketing",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_signature => /:account_slug/signatures/new(.:format)
  // function(account_slug, options)
  new_signature_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"signatures",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_sync_error => /:account_slug/sync_errors/new(.:format)
  // function(account_slug, options)
  new_sync_error_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"sync_errors",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_tag => /:account_slug/tags/new(.:format)
  // function(account_slug, options)
  new_tag_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_task => /:account_slug/tasks/new(.:format)
  // function(account_slug, options)
  new_task_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_tax => /:account_slug/taxes/new(.:format)
  // function(account_slug, options)
  new_tax_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"taxes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_tech => /:account_slug/techs/new(.:format)
  // function(account_slug, options)
  new_tech_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"techs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_vendor => /:account_slug/vendors/new(.:format)
  // function(account_slug, options)
  new_vendor_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"vendors",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// next_available => /:account_slug/connect/next_available(.:format)
  // function(account_slug, options)
  next_available_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"connect",false],[2,[7,"/",false],[2,[6,"next_available",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// note => /:account_slug/notes/:id(.:format)
  // function(account_slug, id, options)
  note_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"notes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// notes => /:account_slug/notes(.:format)
  // function(account_slug, options)
  notes_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"notes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// notify_api_v2_task => /:account_slug/api/v2/tasks/:id/notify.:format
  // function(account_slug, id, format, options)
  notify_api_v2_task_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"notify",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// notify_task => /:account_slug/tasks/:id/notify(.:format)
  // function(account_slug, id, options)
  notify_task_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"notify",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// notify_tech_job => /:account_slug/jobs/:id/notify_tech(.:format)
  // function(account_slug, id, options)
  notify_tech_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"notify_tech",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// oauth_application => /:account_slug/oauth/applications/:id(.:format)
  // function(account_slug, id, options)
  oauth_application_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// oauth_applications => /:account_slug/oauth/applications(.:format)
  // function(account_slug, options)
  oauth_applications_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// oauth_authorization => /:account_slug/oauth/authorize(.:format)
  // function(account_slug, options)
  oauth_authorization_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// oauth_authorized_application => /:account_slug/oauth/authorized_applications/:id(.:format)
  // function(account_slug, id, options)
  oauth_authorized_application_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorized_applications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// oauth_authorized_applications => /:account_slug/oauth/authorized_applications(.:format)
  // function(account_slug, options)
  oauth_authorized_applications_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorized_applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// oauth_introspect => /:account_slug/oauth/introspect(.:format)
  // function(account_slug, options)
  oauth_introspect_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"introspect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// oauth_revoke => /:account_slug/oauth/revoke(.:format)
  // function(account_slug, options)
  oauth_revoke_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"revoke",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// oauth_token => /:account_slug/oauth/token(.:format)
  // function(account_slug, options)
  oauth_token_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// oauth_token_info => /:account_slug/oauth/token/info(.:format)
  // function(account_slug, options)
  oauth_token_info_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"token",false],[2,[7,"/",false],[2,[6,"info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// on_the_way_api_v3_task => /:account_slug/api/v3/tasks/:id/on_the_way.:format
  // function(account_slug, id, format, options)
  on_the_way_api_v3_task_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"on_the_way",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// opportunities => /:account_slug/opportunities(.:format)
  // function(account_slug, options)
  opportunities_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"opportunities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// opportunity => /:account_slug/opportunities/:id(.:format)
  // function(account_slug, id, options)
  opportunity_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// opportunity_attachment => /:account_slug/opportunities/:opportunity_id/attachments/:id(.:format)
  // function(account_slug, opportunity_id, id, options)
  opportunity_attachment_path: Utils.route([["account_slug",true],["opportunity_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"opportunity_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// opportunity_attachments => /:account_slug/opportunities/:opportunity_id/attachments(.:format)
  // function(account_slug, opportunity_id, options)
  opportunity_attachments_path: Utils.route([["account_slug",true],["opportunity_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"opportunity_id",false],[2,[7,"/",false],[2,[6,"attachments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// pay_invoice_self_service_job => /:account_slug/self_service/jobs/:id/pay_invoice(.:format)
  // function(account_slug, id, options)
  pay_invoice_self_service_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"pay_invoice",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// payment => /:account_slug/payments/:id(.:format)
  // function(account_slug, id, options)
  payment_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// payment_history_subscription => /:account_slug/subscription/payment_history(.:format)
  // function(account_slug, options)
  payment_history_subscription_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"subscription",false],[2,[7,"/",false],[2,[6,"payment_history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// payments => /:account_slug/payments(.:format)
  // function(account_slug, options)
  payments_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// permissions_api_v3_employees => /:account_slug/api/v3/employees/permissions.:format
  // function(account_slug, format, options)
  permissions_api_v3_employees_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"permissions",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// permissions_employee => /:account_slug/employees/:id/permissions(.:format)
  // function(account_slug, id, options)
  permissions_employee_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// ping => /ping(.:format)
  // function(options)
  ping_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ping",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// podium_keys => /:account_slug/settings/podium_keys(.:format)
  // function(account_slug, options)
  podium_keys_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"podium_keys",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// podium_keys_settings => /:account_slug/settings/podium_keys(.:format)
  // function(account_slug, options)
  podium_keys_settings_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"podium_keys",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// portal_default => /:account_slug/portal(.:format)
  // function(account_slug, options)
  portal_default_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"portal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// portal_detail => /:account_slug/portal_details/:id(.:format)
  // function(account_slug, id, options)
  portal_detail_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"portal_details",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// portal_opt_out => /:account_slug/connect/opt_out(.:format)
  // function(account_slug, options)
  portal_opt_out_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"connect",false],[2,[7,"/",false],[2,[6,"opt_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// portal_settings => /:account_slug/settings/portal(.:format)
  // function(account_slug, options)
  portal_settings_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"portal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// post => /:account_slug/notifications/:id(.:format)
  // function(account_slug, id, options)
  post_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// postmark => /app/postmark(.:format)
  // function(options)
  postmark_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"postmark",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// postmark_inbound => /app/postmark/inbound(.:format)
  // function(options)
  postmark_inbound_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"inbound",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// postmark_reactivate => /:account_slug/postmark/reactivate(.:format)
  // function(account_slug, options)
  postmark_reactivate_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"reactivate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// posts => /:account_slug/notifications(.:format)
  // function(account_slug, options)
  posts_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// productboard_feedback => /:account_slug/productboard_feedback(.:format)
  // function(account_slug, options)
  productboard_feedback_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"productboard_feedback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// qb_id_search => /:account_slug/quickbooks/accounts/:id/qb_id_search(.:format)
  // function(account_slug, id, options)
  qb_id_search_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"quickbooks",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"qb_id_search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// qboe_sync => /:account_slug/apis/qboe/qboe_sync(.:format)
  // function(account_slug, options)
  qboe_sync_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"apis",false],[2,[7,"/",false],[2,[6,"qboe",false],[2,[7,"/",false],[2,[6,"qboe_sync",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// qbwc_api => /:account_slug/apis/qbwc(.:format)
  // function(account_slug, options)
  qbwc_api_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"apis",false],[2,[7,"/",false],[2,[6,"qbwc",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// quick_add_customer_jobs => /:account_slug/jobs/quick_add_customer(.:format)
  // function(account_slug, options)
  quick_add_customer_jobs_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"quick_add_customer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// quickbooks => /:account_slug/quickbooks(.:format)
  // function(account_slug, options)
  quickbooks_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"quickbooks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// quickbooks_mode => /:account_slug/quickbooks/mode(.:format)
  // function(account_slug, options)
  quickbooks_mode_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"quickbooks",false],[2,[7,"/",false],[2,[6,"mode",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// quickbooks_qwc => /:account_slug/quickbooks/qwc(.:format)
  // function(account_slug, options)
  quickbooks_qwc_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"quickbooks",false],[2,[7,"/",false],[2,[6,"qwc",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// quickbooks_select_all_for_sync => /:account_slug/quickbooks/select_all_for_sync(.:format)
  // function(account_slug, options)
  quickbooks_select_all_for_sync_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"quickbooks",false],[2,[7,"/",false],[2,[6,"select_all_for_sync",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// quickbooks_setup => /:account_slug/quickbooks/setup(.:format)
  // function(account_slug, options)
  quickbooks_setup_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"quickbooks",false],[2,[7,"/",false],[2,[6,"setup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_info => /rails/info(.:format)
  // function(options)
  rails_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_info_properties => /rails/info/properties(.:format)
  // function(options)
  rails_info_properties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"properties",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_info_routes => /rails/info/routes(.:format)
  // function(options)
  rails_info_routes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"routes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_mailers => /rails/mailers(.:format)
  // function(options)
  rails_mailers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"mailers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// recent_activity => /:account_slug/recent_activity(.:format)
  // function(account_slug, options)
  recent_activity_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"recent_activity",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// recover_subdomain => /app/recover_subdomain(.:format)
  // function(options)
  recover_subdomain_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"recover_subdomain",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// recurring_item => /:account_slug/recurring_items/:id(.:format)
  // function(account_slug, id, options)
  recurring_item_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"recurring_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// remove_avatar_employee => /:account_slug/employees/:id/remove_avatar(.:format)
  // function(account_slug, id, options)
  remove_avatar_employee_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"remove_avatar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// remove_invoices_jobs => /:account_slug/jobs/remove_invoices(.:format)
  // function(account_slug, options)
  remove_invoices_jobs_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"remove_invoices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reorder_job_charges_job => /:account_slug/jobs/:id/reorder_job_charges(.:format)
  // function(account_slug, id, options)
  reorder_job_charges_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reorder_job_charges",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// report => /:account_slug/reports/:id(.:format)
  // function(account_slug, id, options)
  report_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports => /:account_slug/reports(.:format)
  // function(account_slug, options)
  reports_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports_settings => /:account_slug/settings/reports(.:format)
  // function(account_slug, options)
  reports_settings_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// request => /:account_slug/requests/:id(.:format)
  // function(account_slug, id, options)
  request_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"requests",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// request_modification_self_service_job => /:account_slug/self_service/jobs/:id/request_modification(.:format)
  // function(account_slug, id, options)
  request_modification_self_service_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"request_modification",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// requests => /:account_slug/requests(.:format)
  // function(account_slug, options)
  requests_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"requests",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reschedule_calendar_event => /:account_slug/calendar/events/:id/reschedule(.:format)
  // function(account_slug, id, options)
  reschedule_calendar_event_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"calendar",false],[2,[7,"/",false],[2,[6,"events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reschedule",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// reschedule_job => /:account_slug/jobs/:id/reschedule(.:format)
  // function(account_slug, id, options)
  reschedule_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reschedule",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// reset_employee_password => /:account_slug/reset_password(.:format)
  // function(account_slug, options)
  reset_employee_password_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"reset_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reset_ical_hashes_employee => /:account_slug/employees/:id/reset_ical_hashes(.:format)
  // function(account_slug, id, options)
  reset_ical_hashes_employee_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reset_ical_hashes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// restart_api_v2_job => /:account_slug/api/v2/jobs/:id/restart.:format
  // function(account_slug, id, format, options)
  restart_api_v2_job_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restart",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// restart_job => /:account_slug/jobs/:id/restart(.:format)
  // function(account_slug, id, options)
  restart_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restart",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// revert_to_opportunity_api_v2_job => /:account_slug/api/v2/jobs/:id/revert_to_opportunity.:format
  // function(account_slug, id, format, options)
  revert_to_opportunity_api_v2_job_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"revert_to_opportunity",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// revert_to_opportunity_job => /:account_slug/jobs/:id/revert_to_opportunity(.:format)
  // function(account_slug, id, options)
  revert_to_opportunity_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"revert_to_opportunity",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// root => /:account_slug(.:format)
  // function(account_slug, options)
  root_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// run_agenda_report => /:account_slug/reports/standard/agenda(.:format)
  // function(account_slug, options)
  run_agenda_report_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"standard",false],[2,[7,"/",false],[2,[6,"agenda",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// run_aging_report => /:account_slug/reports/standard/aging_report(.:format)
  // function(account_slug, options)
  run_aging_report_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"standard",false],[2,[7,"/",false],[2,[6,"aging_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// run_daily_work_orders_report => /:account_slug/reports/standard/daily_work_orders(.:format)
  // function(account_slug, options)
  run_daily_work_orders_report_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"standard",false],[2,[7,"/",false],[2,[6,"daily_work_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// run_report => /:account_slug/reports/standard/:report(.:format)
  // function(account_slug, report, options)
  run_report_path: Utils.route([["account_slug",true],["report",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"standard",false],[2,[7,"/",false],[2,[3,"report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// schedule => /:account_slug/schedule(.:format)
  // function(account_slug, options)
  schedule_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"schedule",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// schedule_task => /:account_slug/schedule/tasks/:id(.:format)
  // function(account_slug, id, options)
  schedule_task_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"schedule",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// schedule_tasks => /:account_slug/schedule/tasks(.:format)
  // function(account_slug, options)
  schedule_tasks_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"schedule",false],[2,[7,"/",false],[2,[6,"tasks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// search => /:account_slug/search(.:format)
  // function(account_slug, options)
  search_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// search_api_v2_items => /:account_slug/api/v2/items/search.:format
  // function(account_slug, format, options)
  search_api_v2_items_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"search",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// search_api_v3_customers => /:account_slug/api/v3/customers/search.:format
  // function(account_slug, format, options)
  search_api_v3_customers_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"search",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// search_api_v3_estimates => /:account_slug/api/v3/estimates/search.:format
  // function(account_slug, format, options)
  search_api_v3_estimates_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"estimates",false],[2,[7,"/",false],[2,[6,"search",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// search_api_v3_invoices => /:account_slug/api/v3/invoices/search.:format
  // function(account_slug, format, options)
  search_api_v3_invoices_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"invoices",false],[2,[7,"/",false],[2,[6,"search",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// search_api_v3_jobs => /:account_slug/api/v3/jobs/search.:format
  // function(account_slug, format, options)
  search_api_v3_jobs_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"search",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// search_api_v3_payments => /:account_slug/api/v3/payments/search.:format
  // function(account_slug, format, options)
  search_api_v3_payments_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"payments",false],[2,[7,"/",false],[2,[6,"search",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// search_api_v3_tasks => /:account_slug/api/v3/tasks/search.:format
  // function(account_slug, format, options)
  search_api_v3_tasks_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[6,"search",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// search_items => /:account_slug/items/search(.:format)
  // function(account_slug, options)
  search_items_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// search_results => /:account_slug/search/results(.:format)
  // function(account_slug, options)
  search_results_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"search",false],[2,[7,"/",false],[2,[6,"results",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// searches => /:account_slug/:search_type/searches(.:format)
  // function(account_slug, search_type, options)
  searches_path: Utils.route([["account_slug",true],["search_type",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[3,"search_type",false],[2,[7,"/",false],[2,[6,"searches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// self_service => /:account_slug/settings/self_service(.:format)
  // function(account_slug, options)
  self_service_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"self_service",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// self_service_customer => /:account_slug/self_service/customers/:id(.:format)
  // function(account_slug, id, options)
  self_service_customer_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// self_service_customers => /:account_slug/self_service/customers(.:format)
  // function(account_slug, options)
  self_service_customers_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// self_service_home => /:account_slug/self_service(.:format)
  // function(account_slug, options)
  self_service_home_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// self_service_inbox_conversation => /:account_slug/self_service/inbox/conversations/:id(.:format)
  // function(account_slug, id, options)
  self_service_inbox_conversation_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[6,"conversations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// self_service_inbox_conversation_messages => /:account_slug/self_service/inbox/conversations/:conversation_id/messages(.:format)
  // function(account_slug, conversation_id, options)
  self_service_inbox_conversation_messages_path: Utils.route([["account_slug",true],["conversation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[6,"conversations",false],[2,[7,"/",false],[2,[3,"conversation_id",false],[2,[7,"/",false],[2,[6,"messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// self_service_inbox_conversations => /:account_slug/self_service/inbox/conversations(.:format)
  // function(account_slug, options)
  self_service_inbox_conversations_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[6,"conversations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// self_service_inbox_messages => /:account_slug/self_service/inbox/messages(.:format)
  // function(account_slug, options)
  self_service_inbox_messages_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[6,"messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// self_service_inbox_root => /:account_slug/self_service/inbox(.:format)
  // function(account_slug, options)
  self_service_inbox_root_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"inbox",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// self_service_inbox_unread => /:account_slug/self_service/inbox/unread(.:format)
  // function(account_slug, options)
  self_service_inbox_unread_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[6,"unread",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// self_service_job => /:account_slug/self_service/jobs/:id(.:format)
  // function(account_slug, id, options)
  self_service_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// self_service_jobs => /:account_slug/self_service/jobs(.:format)
  // function(account_slug, options)
  self_service_jobs_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// self_service_messages => /:account_slug/self_service/messages(.:format)
  // function(account_slug, options)
  self_service_messages_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// self_service_opportunities => /:account_slug/opportunities/self_service(.:format)
  // function(account_slug, options)
  self_service_opportunities_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[6,"self_service",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// self_service_portal => /:account_slug/self_service/portal(.:format)
  // function(account_slug, options)
  self_service_portal_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"portal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// self_service_portal_services => /:account_slug/self_service/portal/services(.:format)
  // function(account_slug, options)
  self_service_portal_services_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"portal",false],[2,[7,"/",false],[2,[6,"services",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// self_service_request => /:account_slug/self_service/requests/:id(.:format)
  // function(account_slug, id, options)
  self_service_request_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"requests",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// self_service_requests => /:account_slug/self_service/requests(.:format)
  // function(account_slug, options)
  self_service_requests_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"requests",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// self_service_session => /:account_slug/self_service/session(.:format)
  // function(account_slug, options)
  self_service_session_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"session",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// send_api_v2_invoice => /:account_slug/api/v2/invoices/:id/send.:format
  // function(account_slug, id, format, options)
  send_api_v2_invoice_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"send",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// send_estimate_api_v2_opportunity => /:account_slug/api/v2/opportunities/:id/send_estimate.:format
  // function(account_slug, id, format, options)
  send_estimate_api_v2_opportunity_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"send_estimate",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// series_job => /:account_slug/jobs/:id/series(.:format)
  // function(account_slug, id, options)
  series_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"series",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// service_requests => /:account_slug/service_requests(.:format)
  // function(account_slug, options)
  service_requests_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"service_requests",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// set_calendar_sync_options_employees => /:account_slug/employees/set_calendar_sync_options(.:format)
  // function(account_slug, options)
  set_calendar_sync_options_employees_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"set_calendar_sync_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// set_document_view_job => /:account_slug/jobs/:id/set_document_view(.:format)
  // function(account_slug, id, options)
  set_document_view_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"set_document_view",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// settings => /:account_slug/settings(.:format)
  // function(account_slug, options)
  settings_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// settings_items => /:account_slug/items/settings(.:format)
  // function(account_slug, options)
  settings_items_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"items",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// setup => /:account_slug/setup/:id(.:format)
  // function(account_slug, id, options)
  setup_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// setup_attachment_upload => /:account_slug/attachments/setup_attachment(.:format)
  // function(account_slug, options)
  setup_attachment_upload_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"attachments",false],[2,[7,"/",false],[2,[6,"setup_attachment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// setup_marketing => /:account_slug/setup_marketing/:id(.:format)
  // function(account_slug, id, options)
  setup_marketing_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"setup_marketing",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// setup_marketing_index => /:account_slug/setup_marketing(.:format)
  // function(account_slug, options)
  setup_marketing_index_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"setup_marketing",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// setup_self_service_customer => /:account_slug/customers/:id/self_service(.:format)
  // function(account_slug, id, options)
  setup_self_service_customer_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"self_service",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// share_ical_employee => /:account_slug/employees/:id/share_ical(.:format)
  // function(account_slug, id, options)
  share_ical_employee_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"share_ical",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sign_self_service_job => /:account_slug/self_service/jobs/:id/sign(.:format)
  // function(account_slug, id, options)
  sign_self_service_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"sign",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// signature => /:account_slug/signatures/:id(.:format)
  // function(account_slug, id, options)
  signature_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"signatures",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// signatures => /:account_slug/signatures(.:format)
  // function(account_slug, options)
  signatures_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"signatures",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// signup => /app/signup(.:format)
  // function(options)
  signup_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"signup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// snapshot_api_v3_customer => /:account_slug/api/v3/customers/:id/snapshot.:format
  // function(account_slug, id, format, options)
  snapshot_api_v3_customer_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"snapshot",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// sources_api_v3_customers => /:account_slug/api/v3/customers/sources.:format
  // function(account_slug, format, options)
  sources_api_v3_customers_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"sources",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// start_api_v2_job => /:account_slug/api/v2/jobs/:id/start.:format
  // function(account_slug, id, format, options)
  start_api_v2_job_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"start",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// start_job => /:account_slug/jobs/:id/start(.:format)
  // function(account_slug, id, options)
  start_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"start",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// statement_subscription => /:account_slug/subscription/statement(.:format)
  // function(account_slug, options)
  statement_subscription_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"subscription",false],[2,[7,"/",false],[2,[6,"statement",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// stop_api_v2_job => /:account_slug/api/v2/jobs/:id/stop.:format
  // function(account_slug, id, format, options)
  stop_api_v2_job_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"stop",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// stop_job => /:account_slug/jobs/:id/stop(.:format)
  // function(account_slug, id, options)
  stop_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"stop",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// stripe => /app/stripe(.:format)
  // function(options)
  stripe_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"stripe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// stripe_checkout_sessions_cancel => /:account_slug/stripe_checkout_sessions/cancel(.:format)
  // function(account_slug, options)
  stripe_checkout_sessions_cancel_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"stripe_checkout_sessions",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// stripe_checkout_sessions_complete => /:account_slug/stripe_checkout_sessions/complete(.:format)
  // function(account_slug, options)
  stripe_checkout_sessions_complete_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"stripe_checkout_sessions",false],[2,[7,"/",false],[2,[6,"complete",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// stripe_checkout_sessions_create => /:account_slug/stripe_checkout_sessions/create(.:format)
  // function(account_slug, options)
  stripe_checkout_sessions_create_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"stripe_checkout_sessions",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// stripe_checkout_sessions_webhook => /app/stripe_checkout_sessions/webhook(.:format)
  // function(options)
  stripe_checkout_sessions_webhook_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"stripe_checkout_sessions",false],[2,[7,"/",false],[2,[6,"webhook",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// subscription => /:account_slug/subscription(.:format)
  // function(account_slug, options)
  subscription_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"subscription",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sync_error => /:account_slug/sync_errors/:id(.:format)
  // function(account_slug, id, options)
  sync_error_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"sync_errors",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sync_errors => /:account_slug/sync_errors(.:format)
  // function(account_slug, options)
  sync_errors_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"sync_errors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sync_job => /:account_slug/apis/qboe/sync_job(.:format)
  // function(account_slug, options)
  sync_job_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"apis",false],[2,[7,"/",false],[2,[6,"qboe",false],[2,[7,"/",false],[2,[6,"sync_job",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sync_status => /:account_slug/apis/qboe/sync_status(.:format)
  // function(account_slug, options)
  sync_status_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"apis",false],[2,[7,"/",false],[2,[6,"qboe",false],[2,[7,"/",false],[2,[6,"sync_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// tag => /:account_slug/tags/:id(.:format)
  // function(account_slug, id, options)
  tag_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// tags => /:account_slug/tags(.:format)
  // function(account_slug, options)
  tags_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// task => /:account_slug/tasks/:id(.:format)
  // function(account_slug, id, options)
  task_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// task_list_tasks => /:account_slug/tasks/task_list(.:format)
  // function(account_slug, options)
  task_list_tasks_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"tasks",false],[2,[7,"/",false],[2,[6,"task_list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// tasks => /:account_slug/tasks(.:format)
  // function(account_slug, options)
  tasks_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"tasks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// tax => /:account_slug/taxes/:id(.:format)
  // function(account_slug, id, options)
  tax_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"taxes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// taxes => /:account_slug/taxes(.:format)
  // function(account_slug, options)
  taxes_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"taxes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// taxes_api_v3_customers => /:account_slug/api/v3/customers/taxes.:format
  // function(account_slug, format, options)
  taxes_api_v3_customers_path: Utils.route([["account_slug",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"taxes",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]),
// tech => /:account_slug/techs/:id(.:format)
  // function(account_slug, id, options)
  tech_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"techs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// techs => /:account_slug/techs(.:format)
  // function(account_slug, options)
  techs_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"techs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// templates => /:account_slug/settings/templates(.:format)
  // function(account_slug, options)
  templates_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// test_app_provider => /:account_slug/test/test_app_provider(.:format)
  // function(account_slug, options)
  test_app_provider_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"test",false],[2,[7,"/",false],[2,[6,"test_app_provider",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// thank_you => /:account_slug/thank_you(.:format)
  // function(account_slug, options)
  thank_you_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"thank_you",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// toggle_active_customer => /:account_slug/customers/:id/toggle_active(.:format)
  // function(account_slug, id, options)
  toggle_active_customer_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"toggle_active",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// toggle_paid_api_v2_invoice => /:account_slug/api/v2/invoices/:id/toggle_paid.:format
  // function(account_slug, id, format, options)
  toggle_paid_api_v2_invoice_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"toggle_paid",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// toggle_paid_api_v3_invoice => /:account_slug/api/v3/invoices/:id/toggle_paid.:format
  // function(account_slug, id, format, options)
  toggle_paid_api_v3_invoice_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v3",false],[2,[7,"/",false],[2,[6,"invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"toggle_paid",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// toggle_paid_invoice => /:account_slug/invoices/:id/toggle_paid(.:format)
  // function(account_slug, id, options)
  toggle_paid_invoice_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"toggle_paid",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// toggle_setup_index => /:account_slug/setup/toggle(.:format)
  // function(account_slug, options)
  toggle_setup_index_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"toggle",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// toggle_status_invoice => /:account_slug/invoices/:id/toggle_status(.:format)
  // function(account_slug, id, options)
  toggle_status_invoice_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"toggle_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// trial_expires_soon => /:account_slug/trial_expires_soon(.:format)
  // function(account_slug, options)
  trial_expires_soon_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"trial_expires_soon",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// twilio_message => /app/twilio_message(.:format)
  // function(options)
  twilio_message_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"twilio_message",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// twilio_status_callbacks => /app/twilio_status_callbacks(.:format)
  // function(options)
  twilio_status_callbacks_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[2,[7,"/",false],[2,[6,"twilio_status_callbacks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// unarchive_inbox_conversations => /:account_slug/inbox/conversations/unarchive(.:format)
  // function(account_slug, options)
  unarchive_inbox_conversations_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[6,"conversations",false],[2,[7,"/",false],[2,[6,"unarchive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// unarchive_self_service_inbox_conversations => /:account_slug/self_service/inbox/conversations/unarchive(.:format)
  // function(account_slug, options)
  unarchive_self_service_inbox_conversations_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"inbox",false],[2,[7,"/",false],[2,[6,"conversations",false],[2,[7,"/",false],[2,[6,"unarchive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// unhold_api_v2_job => /:account_slug/api/v2/jobs/:id/unhold.:format
  // function(account_slug, id, format, options)
  unhold_api_v2_job_path: Utils.route([["account_slug",true],["id",true],["format",true]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"v2",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unhold",false],[2,[8,".",false],[3,"format",false]]]]]]]]]]]]]]),
// unhold_job => /:account_slug/jobs/:id/unhold(.:format)
  // function(account_slug, id, options)
  unhold_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unhold",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_notification_preferences_employee => /:account_slug/employees/:id/update_notification_preferences(.:format)
  // function(account_slug, id, options)
  update_notification_preferences_employee_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_notification_preferences",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_permission_employee => /:account_slug/employees/:id/update_permission(.:format)
  // function(account_slug, id, options)
  update_permission_employee_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_permission",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_statuses_jobs => /:account_slug/jobs/update_statuses(.:format)
  // function(account_slug, options)
  update_statuses_jobs_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"update_statuses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_statuses_opportunities => /:account_slug/opportunities/update_statuses(.:format)
  // function(account_slug, options)
  update_statuses_opportunities_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"opportunities",false],[2,[7,"/",false],[2,[6,"update_statuses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// upload_customers => /:account_slug/customers/upload(.:format)
  // function(account_slug, options)
  upload_customers_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"upload",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// upload_customers_mappings => /:account_slug/customers/upload/map(.:format)
  // function(account_slug, options)
  upload_customers_mappings_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"upload",false],[2,[7,"/",false],[2,[6,"map",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// vendor => /:account_slug/vendors/:id(.:format)
  // function(account_slug, id, options)
  vendor_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"vendors",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// vendors => /:account_slug/vendors(.:format)
  // function(account_slug, options)
  vendors_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"vendors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// warehouse_activities => /:account_slug/warehouse/activities(.:format)
  // function(account_slug, options)
  warehouse_activities_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"warehouse",false],[2,[7,"/",false],[2,[6,"activities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// weblead => /:account_slug/settings/weblead(.:format)
  // function(account_slug, options)
  weblead_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"weblead",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// work_location_customer => /:account_slug/customers/:id/work_location(.:format)
  // function(account_slug, id, options)
  work_location_customer_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"work_location",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// work_order_job => /:account_slug/jobs/:id/work_order(.:format)
  // function(account_slug, id, options)
  work_order_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"work_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// work_order_self_service_job => /:account_slug/self_service/jobs/:id/work_order(.:format)
  // function(account_slug, id, options)
  work_order_self_service_job_path: Utils.route([["account_slug",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"self_service",false],[2,[7,"/",false],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"work_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// work_orders => /:account_slug/settings/work_orders(.:format)
  // function(account_slug, options)
  work_orders_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"work_orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// work_tags => /:account_slug/tags/work(.:format)
  // function(account_slug, options)
  work_tags_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[6,"work",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// xero => /:account_slug/xero(.:format)
  // function(account_slug, options)
  xero_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"xero",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// xero_callback => /:account_slug/xero/callback(.:format)
  // function(account_slug, options)
  xero_callback_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"xero",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// xero_configure => /:account_slug/xero/configure(.:format)
  // function(account_slug, options)
  xero_configure_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"xero",false],[2,[7,"/",false],[2,[6,"configure",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// xero_connect => /:account_slug/xero/connect(.:format)
  // function(account_slug, options)
  xero_connect_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"xero",false],[2,[7,"/",false],[2,[6,"connect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// xero_disconnect => /:account_slug/xero/disconnect(.:format)
  // function(account_slug, options)
  xero_disconnect_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"xero",false],[2,[7,"/",false],[2,[6,"disconnect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// xero_setup => /:account_slug/xero/setup(.:format)
  // function(account_slug, options)
  xero_setup_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"xero",false],[2,[7,"/",false],[2,[6,"setup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// xero_sync => /:account_slug/xero/sync(.:format)
  // function(account_slug, options)
  xero_sync_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"xero",false],[2,[7,"/",false],[2,[6,"sync",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// xero_sync_job => /:account_slug/xero/sync_job(.:format)
  // function(account_slug, options)
  xero_sync_job_path: Utils.route([["account_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"account_slug",false],[2,[7,"/",false],[2,[6,"xero",false],[2,[7,"/",false],[2,[6,"sync_job",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      Object.defineProperty(routes, 'defaults', {
        get: function() {
          throw new Error("Routes" + ".defaults is removed. Use " + "Routes" + ".configure() instead.");
        },
        set: function(value) {}
      });
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "Routes", routes);
    }
  };

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return Utils.make();
    });
  } else {
    Utils.make();
  }

}).call(this);
