export const DEFAULT_SLUG = "app"
export const DEBOUNCE_TIMEOUT = 1000

/**
 * The amount of additional phones/emails that a user can add for each customer.
 *
 * Since each additional phone/email is mapped to an additional contact,
 * this means a customer can have a maximum of 6 additional contacts
 */
export const MAX_ADDITIONAL_EMAILS = 3
export const MAX_ADDITIONAL_PHONES = 3
