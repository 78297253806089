/* globals gon location */

import * as CONSTANTS from "./CONSTANTS.js"

/**
 * labs - returns the value of the lab specified as an argument
 *
 * @function
 *
 * @see app/controllers/application_controller.rb
 * @notes
 *
 * The server side supports the concept of "labs" to turn features on and off.
 * We use them on the client side as well by getting them passed down in the
 * `gon` global, imported here.
 */
export const labs = lab => (gon && gon.labs ? gon.labs[lab] : false)

export const getCurrentUser = () =>
  (window.gon && window.gon.current_user) || null

/**
 * accountSlug - return the current account slug
 *
 * @function
 *
 * @notes
 *
 * The account slug should be passed down in the gon global object,
 * but it if isn't, we try to determine the slug based on the current
 * window location.
 */
export const accountSlug = () => {
  let slug = CONSTANTS.DEFAULT_SLUG
  if (gon && gon.account_slug) {
    slug = gon.account_slug
  } else if (window.location && window.location.pathname) {
    const first_path_item = window.location.pathname.split("/")[1]
    if (first_path_item) slug = first_path_item
  }
  return slug
}

/**
 * addAccountSlug - prepends the account slug onto a url provided the url:
 * 1. is not absolute (has the https? scheme)
 * 2. does not already begin with the slug
 */
export const addAccountSlug = url => {
  const slug = accountSlug()
  if (
    !slug ||
    url.startsWith("http") ||
    url.startsWith(slug) ||
    url.startsWith("/" + slug)
  ) {
    return url
  } else {
    return "/" + slug + (url.startsWith("/") ? url : "/" + url)
  }
}
/** alias for addAccountSlug */
export const prependAccountSlug = url => addAccountSlug(url)

export function isSelfServicePortal() {
  const pathPieces = window.location.pathname.split("/")

  // window.location.pathname always starts with a slash (/).
  // https://developer.mozilla.org/en-US/docs/Web/API/Location/pathname
  // Because of this, once we use split, the first element is always an empty string
  // We need to take that empty string into account when trying to access elements.
  // Example:
  // /shasta/jobs/12 will become ["", "shasta", "jobs", "12"]
  //
  // /shasta/self_service will become ["", "shasta", "self_service"]

  return pathPieces.length > 2 && pathPieces[2] === "self_service"
}

/**
 * getGoogleMapsJavaScriptAPIKey - function to retrieve the map key from the `gon` structure
 *
 * @todo this should not be necessary since it's available via a graphql query
 */
export const getGoogleMapsJavaScriptAPIKey = () => {
  return gon.map_key
}
/**
 * googleMapsJavaScriptAPIKey - constant form of above
 */
export const googleMapsJavaScriptAPIKey = gon.map_key || ""

/**
 * googleMapsJavaScriptClientKey - the client key, used by the Places API
 */
export const googleMapsJavaScriptClientKey = gon.client_key || ""

/**
 * capitalize - convert the first character in a string to uppercase and the rest into lower case
 */
export const capitalize = s => {
  return s.slice(0, 1).toUpperCase() + s.slice(1).toLowerCase()
}
/** alias for capitalize */
export const upcaseFirst = s => capitalize(s)

/**
 * titlize - convert *words* in a string to capitalized. As a side effect, it squashes all white space.
 */
export const titlize = s => {
  return s
    .split(/\s+/)
    .filter(w => w)
    .map(w => capitalize(w))
    .join(" ")
}
/** alias for titlize */
export const titleize = s => titlize(s)

/**
 * slugify -  convert to lowercase, remove all extraneous characters from a string, trim, replace streams of spaces with dashes
 */
export function slugify(s) {
  return s
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, " ")
    .trim()
    .replace(/ +/g, "-")
}

export const isMac =
  typeof navigator != "undefined" ? /Mac/.test(navigator.platform) : false

export { jsRoutes } from "./jsRoutes"
