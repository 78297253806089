import jsRoutesGenerated from "./js_routes"

/*
 * The `js_routes` file coming in from the ruby gem 'js-routes' has a small issue
 * that only shows up in comparison with the webpack environment vs. the jest
 * testing environment. The following shim takes care of loading the routes file
 * correctly in either scenario, so the components utilizing js routes get the
 * proper form for that environment.
 *
 * Components should load jsRoutes from this loader:
 *
 *     import {jsRoutes} from 'utils/jsRoutes'
 *
 * The issue is that the js_routes.js file does not work under commonJS.
 */
export const jsRoutes = jsRoutesGenerated.Routes || jsRoutesGenerated
